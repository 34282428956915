import { useGetList, useGetMany } from 'react-admin'

const useLatestBids = () => {
  const { data: bids, loading: loadingBids } = useGetList(
    'bids',
    {
      page: 1,
      perPage: 5
    },
    {
      field: 'createdAt',
      order: 'DESC'
    }
  )

  const { data: customers } = useGetMany(
    'customers',
    Object.values(bids)?.map((bid) => bid.customerId) || []
  )

  return {
    bids:
      Object.values(bids)?.map((bid) => {
        const customer = Object.values(customers).find((customer) => {
          return customer?._id && customer._id === bid.customerId
        })
        if (!customer) return bid
        return {
          ...bid,
          customerName: customer.identity.name
        }
      }) || [],
    loadingBids
  }
}

export default useLatestBids
