import { useForm, useFormState } from 'react-final-form'
import { useEffect } from 'react'
import { roundPrice } from '../../../../../elements/PriceField'
import { EDiscountKind, EDiscountType, EDiscountUnit } from '../../../enums'

const useSubtotals = () => {
  const form = useForm()
  const { values } = useFormState()
  const { subtotals, options, salesTax, modifiers } = values

  useEffect(() => {
    if (!subtotals || !Object.keys(subtotals).length) return

    const totals = { ...subtotals }
    Object.keys(totals).forEach(
      (key) =>
        (totals[key] = roundPrice(totals[key] * (1 + (salesTax || 0) / 100)))
    )

    if (!modifiers) {
      form.change('totals', totals)
      return
    }
    modifiers.forEach((modifier) => {
      options.forEach((option) => {
        const elIndex = modifier.options.findIndex((elId) => elId === option.id)
        if (elIndex >= 0 && modifier.kind === EDiscountKind.post) {
          const value =
            modifier.unit === EDiscountUnit.dollar
              ? modifier.value
              : roundPrice((modifier.value * (totals?.[option.id] || 0)) / 100)

          totals[option.id] +=
            value * (modifier.type === EDiscountType.discount ? -1 : 1)

          if (!modifier.dollarValue) {
            modifier.dollarValue = {}
          }
          modifier.dollarValue[option.id] = value
        }
      })
    })

    Object.keys(totals).forEach(
      (key) => (totals[key] = roundPrice(totals[key]))
    )

    form.change('totals', totals)
  }, [modifiers, options, subtotals, salesTax])
}

export default useSubtotals
