import { useFormState } from 'react-final-form'
import {
  GridList,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery,
  Tooltip
} from '@material-ui/core'
import React from 'react'
import get from 'lodash/get'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/ErrorTwoTone'
import CheckIcon from '@material-ui/icons/CheckCircleTwoTone'
import { green, orange } from '@material-ui/core/colors'
import { EBidElementTypes, EDiscountKind, EDiscountType } from './enums'
import red from '@material-ui/core/colors/red'

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      margin: '0 15px'
    }
  },
  list: {
    margin: '16px !important'
  }
}))

const valuesToCheck = {
  jobType: 'Job Type',
  taxJurisdiction: 'Tax/Permit Jurisdiction',
  salesTax: 'Sales Tax',
  downPayment: 'Down Payment'
}

const iterateItemsType = (items) => {
  const result = {}
  Object.keys(EBidElementTypes).forEach((type) => {
    result[type] = !!items?.find((element) => element.type === type)
  })
  return result
}
const iterateModifiers = (modifiers) => {
  const result = {}
  Object.keys(EDiscountKind).forEach((kind) => {
    Object.keys(EDiscountType).forEach((type) => {
      result[`${kind}-${type}`] = !!modifiers?.find(
        (modifier) => modifier.type === type && modifier.kind === kind
      )
    })
  })
  return result
}

const ListElement = ({
  key,
  isValid,
  text,
  isRequired,
  helperText,
  ...rest
}) => {
  return (
    <ListItem key={key} {...rest}>
      <ListItemIcon>
        {isValid ? (
          <CheckIcon style={{ color: green[300] }} />
        ) : (
          <ErrorIcon style={{ color: isRequired ? red[300] : orange[300] }} />
        )}
      </ListItemIcon>
      {helperText ? (
        <Tooltip placement='top' title={helperText}>
          <ListItemText primary={text} />
        </Tooltip>
      ) : (
        <ListItemText primary={text} />
      )}
    </ListItem>
  )
}

const SummaryList = () => {
  const { values, errors } = useFormState()
  const styles = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const itemTypes = iterateItemsType(values.elements)
  const modifiers = iterateModifiers(values.modifiers)

  return (
    <Paper className={styles.container}>
      <GridList cellHeight={44} cols={isSmall ? 2 : 4} className={styles.list}>
        {Object.keys(valuesToCheck).map((key) => {
          return (
            <ListElement
              key={key}
              isValid={get(values, key) != null}
              text={valuesToCheck[key]}
            />
          )
        })}
        {Object.keys(modifiers).map((key) => {
          return (
            <ListElement
              key={key}
              isValid={modifiers[key]}
              text={key}
            />
          )
        })}
        {Object.keys(itemTypes).map((key) => {
          return (
            <ListElement
              key={key}
              isValid={itemTypes[key]}
              text={key}
            />
          )
        })}
        {errors.elements ? (
          <ListElement
            key={'elements-error'}
            isValid={false}
            isRequired
            text={'Invalid Items'}
            helperText={
              'Invalid items are marked red. They no longer exist in origin source. Remove them before saving.'
            }
          />
        ) : null}
      </GridList>
    </Paper>
  )
}

export default SummaryList
