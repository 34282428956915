import React from 'react'
import { createElement } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { MenuItemLink, getResources, useTranslate } from 'react-admin'
import { withRouter } from 'react-router-dom'
import startCase from 'lodash/startCase'
import { Dashboard } from '@material-ui/icons'
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from 'clsx'

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '1.5em'
      }
    }
  }),
  { name: 'RaMenu' }
)

const Menu = (props) => {
  const {
    onMenuClick,
    logout,
    hasDashboard,
    className,
    dense,
    classes: classesOverride,
    ...rest
  } = props
  const classes = useStyles(props)
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const translate = useTranslate()

  return (
    <div className={clsx(classes.main, className)} {...rest}>
      {hasDashboard && (
        <MenuItemLink
          onClick={onMenuClick}
          to='/'
          primaryText={translate('ra.page.dashboard')}
          leftIcon={<Dashboard />}
          exact
          dense={dense}
          sidebarIsOpen={open}
        />
      )}
      {resources
        .filter((r) => r.hasList)
        .map((resource) => {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={startCase(
                (resource.options && resource.options.label) || resource.name
              )}
              leftIcon={resource.icon ? createElement(resource.icon) : null}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )
        })}
      {isXSmall && logout}
    </div>
  )
}

export default withRouter(Menu)
