import React, { useRef, useState } from 'react'

import { Card, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import useData from './useData'
import { startOfDay, subDays } from 'date-fns'
import useDiagram from './useDiagram'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'
import { LocalizationProvider, DatePicker } from '@material-ui/pickers'
import TextField from '@material-ui/core/TextField'
import ProgressOverlay from '../../../elements/ProgressOverlay'

const ColumnDiagram = ({ type }) => {
  const [to, setTo] = useState(new Date())
  const [from, setFrom] = useState(startOfDay(subDays(to, 7)))
  const [range, setRange] = useState('days')
  const ref = useRef()
  const { data, isLoading } = useData(from, to, range, type)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  useDiagram(ref, data, type)

  return (
    <>
      <Grid container spacing={2} direction={'row'}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid
                container
                spacing={2}
                direction={isSmall ? 'column' : 'row'}
              >
                <Grid
                  item
                  container
                  direction={'column'}
                  xs={12}
                  lg={2}
                  spacing={2}
                >
                  <Grid item>
                    <Select
                      value={range}
                      onChange={(e) => {
                        setRange(e.target.value)
                      }}
                      style={{
                        width: '100%',
                        maxWidth: isSmall ? '100%' : '210px'
                      }}
                    >
                      <MenuItem value={'days'}>Days</MenuItem>
                      <MenuItem value={'weeks'}>Weeks</MenuItem>
                      <MenuItem value={'months'}>Months</MenuItem>
                      <MenuItem value={'years'}>Years</MenuItem>
                    </Select>
                  </Grid>
                  <LocalizationProvider dateAdapter={DateFnsAdapter}>
                    <Grid item>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} fullWidth />
                        )}
                        value={from}
                        onChange={(date) => setFrom(date)}
                      />
                    </Grid>
                    <Grid item>
                      <DatePicker
                        renderInput={(props) => (
                          <TextField {...props} fullWidth />
                        )}
                        value={to}
                        onChange={(date) => setTo(date)}
                      />
                    </Grid>
                  </LocalizationProvider>
                </Grid>

                <Grid item xs={12} lg={10}>
                  <ProgressOverlay show={isLoading}>
                    <canvas ref={ref} />
                  </ProgressOverlay>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default ColumnDiagram
