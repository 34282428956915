import React from 'react'
import { useFormState } from 'react-final-form'

import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin'
import { Box, Grid, Paper, Typography } from '@material-ui/core'

import TitlebarGridList from './../../elements/TitlebarGridList'
import RichTextInput from 'ra-input-rich-text'
import CustomLoader from '../../elements/Loader'

const ConnectBidsImages = (props) => {
  const { values } = useFormState()
  let { options, images } = values

  if (!props.settings) return <CustomLoader />

  const settingsImages = props.settings.find((el) => el.type === 'images')
  if (!settingsImages) return 'no data'

  return (
    <>
      {options.map((el) => {
        let data = false
        const curImages = images?.[el.id]

        if (curImages) {
          data = curImages.map((elementImageTitle) => {
            //check if title of image is in settings entity
            const findImage = settingsImages.data.find(
              (element) => element?.title === elementImageTitle
            )

            return {
              title: elementImageTitle,
              img:
                findImage?.url ||
                'https://via.placeholder.com/200x200.png?text=Image+Not+Found'
            }
          })
        }
        return (
          <Box key={el.id} mb={3}>
            <Paper elevation={2} style={{ padding: 15 }}>
              <Grid container fullWidth spacing={5}>
                <Grid item lg={6}>
                  <Typography variant='h6'>{el.name}</Typography>
                  <RichTextInput
                    source={`optionDescriptions.${el.id}`}
                    label={`Description`}
                    fullWidth
                  />

                  <ArrayInput label={`Images`} source={`images.${el.id}`}>
                    <SimpleFormIterator
                      disableAdd={images?.[el.id] && images[el.id].length === 4}
                    >
                      <TextInput label='title' source='' />
                    </SimpleFormIterator>
                  </ArrayInput>
                </Grid>
                <Grid item lg={6}>
                  {data && (
                    <TitlebarGridList cols={4} height={100} data={data} />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Box>
        )
      })}
    </>
  )
}

export default ConnectBidsImages
