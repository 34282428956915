import React from 'react'
import { TextInput } from 'react-admin'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import throttle from 'lodash/throttle'
import flatten from 'lodash/flatten'
import app from '../client/feathersClient'

import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import {
  BACKGROUND_FETCH_ERROR,
  BACKGROUND_FETCH_ERROR_2,
  BACKGROUND_FETCH_PRICE
} from '../reducer/types'

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'inherit',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: '16px',
      '& > div': {
        margin: 0
      }
    }
  }
}))

export default function AsyncAutocomplete({
  service,
  serviceKeys,
  onChange,
  ...rest
}) {
  const [value, setValue] = React.useState(null)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState([])
  const styles = useStyles()
  const dispatch = useDispatch()

  const fetch = React.useMemo(
    () =>
      throttle(async (inputValue, callback) => {
        const responses = await Promise.all(
          serviceKeys.map((key) =>
            app.service(service).find({
              query: {
                [key]: {
                  $regex: inputValue,
                  $options: 'i'
                },
                $limit: 10
              }
            })
          )
        )
        callback(flatten(responses.map((res) => res.data)))
      }, 300),
    [service, serviceKeys]
  )
  const updatePrice = async (id) => {
    dispatch({
      type: BACKGROUND_FETCH_ERROR,
      payload: false
    })
    dispatch({
      type: BACKGROUND_FETCH_ERROR_2,
      payload: false
    })
    dispatch({
      type: BACKGROUND_FETCH_PRICE,
      payload: true
    })
    try {
      const res = await app.service('gensco/integrate').get(id)
      setValue((v) => {
        if (!res.price && res.price !== 0) {
          dispatch({
            type: BACKGROUND_FETCH_ERROR_2,
            payload: true
          })
        }
        const newV = { ...v, price: res.price }
        onChange && onChange(newV)
        return newV
      })
    } catch (e) {
      dispatch({
        type: BACKGROUND_FETCH_ERROR,
        payload: true
      })
    } finally {
      dispatch({
        type: BACKGROUND_FETCH_PRICE,
        payload: false
      })
    }
  }

  React.useEffect(() => {
    fetch(inputValue, (results) => {
      let newOptions = []

      if (value) {
        newOptions = [value]
      }

      if (results) {
        newOptions = [...newOptions, ...results]
      }

      setOptions(newOptions)
    })
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      className={styles.container}
      getOptionLabel={(option) => `${option.itemID} - ${option.description}`}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={async (event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        if (newValue) updatePrice(newValue._id)

        setValue(newValue)
        setInputValue(
          newValue ? `${newValue.itemID} - ${newValue.description}` : ''
        )
        onChange && onChange(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => <TextInput {...params} {...rest} />}
      renderOption={(option) => {
        const name = `${option.itemID} - ${option.description}`
        const startIndex = name.toLowerCase().search(inputValue)
        const endIndex = startIndex + inputValue?.length

        return (
          <Grid container alignItems='center'>
            <Grid item xs>
              <Typography variant='body2' color='textSecondary'>
                {name.split('').map((letter, index) => {
                  const isBold =
                    startIndex >= 0 && startIndex <= index && endIndex > index
                  return (
                    <span
                      key={index}
                      style={{ fontWeight: isBold ? 700 : 400 }}
                    >
                      {letter}
                    </span>
                  )
                })}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}
