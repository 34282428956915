import * as React from 'react'
import { forwardRef, useCallback } from 'react'
import { MenuItem, ListItemIcon, useMediaQuery } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ExitIcon from '@material-ui/icons/PowerSettingsNew'
import { useHistory } from 'react-router-dom'
import app from '../client/feathersClient'
import clsx from 'clsx'

const useStyles = makeStyles(
  (theme) => ({
    menuItem: {
      color: theme.palette.text.secondary
    },
    icon: { minWidth: theme.spacing(5) }
  }),
  { name: 'RaLogout' }
)

const LogoutButton = forwardRef((props, ref) => {
  const { className, icon, ...rest } = props
  const history = useHistory()
  const handleClick = useCallback(async () => {
    try {
      window.localStorage.removeItem('userId')
      await app.logout()
    } finally {
      history.push('/login')
    }
  }, [])

  const classes = useStyles(props)
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  return (
    <MenuItem
      className={clsx('logout', classes.menuItem, className)}
      onClick={handleClick}
      ref={ref}
      component={isXSmall ? 'span' : 'li'}
      {...rest}
    >
      <ListItemIcon className={classes.icon}>
        {icon ? icon : <ExitIcon />}
      </ListItemIcon>
      Logout
    </MenuItem>
  )
})

export default LogoutButton
