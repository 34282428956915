import app from '../../client/feathersClient'
import { useEffect, useState } from 'react'
import subDays from 'date-fns/subDays'

const statuses = ['pending', 'sold', 'lost']

const useBidsStats = () => {
  const [status, setStatus] = useState({
    soldTotal: 0,
    pendingTotal: 0,
    lostTotal: 0
  })
  useEffect(() => {
    ;(async function () {
      const promises = statuses.map(async (status) => {
        const res = await app.service('bids').find({
          query: {
            status: status,
            $limit: 0,
            createdAt: { $gt: subDays(new Date(), 30) }
          }
        })
        return res.total
      })

      const [pendingTotal, soldTotal, lostTotal] = await Promise.all(promises)
      setStatus({
        soldTotal,
        pendingTotal,
        lostTotal
      })
    })()
  }, [])

  return status
}

export default useBidsStats
