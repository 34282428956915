import { SelectField, useGetOne, Labeled } from 'react-admin'
import React from 'react'
import { useFormState } from 'react-final-form'
import useConfig from '../../../hooks/useConfig'

const ConfigField = (props) => {
  const { values } = useFormState()
  const { data: customer } = useGetOne('customers', values.customerId)
  const config = useConfig()

  return (
    <Labeled label={'Customer Lead source'} style={{ marginBottom: '16px' }}>
      <SelectField
        {...props}
        record={customer}
        source={'leadSource'}
        choices={config.leadSource}
        emptyText={'--Not set--'}
      />
    </Labeled>
  )
}

export default ConfigField
