import React from 'react'
import PropTypes from 'prop-types'
import { NumberInput } from 'react-admin'

const PriceInput = ({ source, ...props }) => {
  return (
    <NumberInput
      format={(value) => (value || value === 0 ? value / 100 : null)}
      parse={(value) => (value || value === 0 ? Math.round(value * 100) : null)}
      source={source}
      min={0}
      step={0.01}
      {...props}
    />
  )
}

PriceInput.propTypes = {
  source: PropTypes.string.isRequired,
  helperText: Promise.string
}

export default PriceInput
