import { makeStyles } from '@material-ui/core/styles'
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { blue, green, red } from '@material-ui/core/colors'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    height: '100%',
    minHeight: '80px'
  },
  label: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    padding: '8px 0',
    background: ({ variant }) =>
      variant === 'red' ? red[50] : variant === 'blue' ? blue[50] : green[50],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    transform: 'translate(-16px, -8px)'
  },
  text: {
    fontSize: '11px',
    fontWeight: 700,
    transform: 'rotate(-90deg)',
    letterSpacing: '2px',
    color: 'black',
    whiteSpace: 'nowrap'
  },
  emptyTitle: {
    opacity: 0.15,
    padding: '25px 0',
    textAlign: 'center'
  }
}))

const ElementsWrapper = ({ children, label, variant }) => {
  const styles = useStyles({ variant })

  const hasChildren = useMemo(() => {
    try {
      return children.filter((child) => child).length
    } catch {
      return true
    }
  }, [children])

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <Typography className={styles.text}>{label}</Typography>
      </div>
      {!hasChildren ? (
        <Typography variant={'h5'} className={styles.emptyTitle}>
          {'No elements'}
        </Typography>
      ) : (
        children
      )}
    </div>
  )
}

ElementsWrapper.propTypes = {
  label: PropTypes.string.isRequired
}

export default ElementsWrapper
