import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import { useLocation } from 'react-router-dom'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import { Notification, useTranslate, useLogin, useNotify } from 'react-admin'
import ResetPasswordDialog from './ResetPasswordDialog'
import renderTextInput from '../../elements/renderTextInput'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background:
      'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%);'
  },
  card: {
    minWidth: 300,
    marginTop: '6em'
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  icon: {
    backgroundColor: theme.palette.secondary.main
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500]
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em'
  },
  actions: {
    padding: '0 1em 1em 1em'
  },
  resetButton: {
    opacity: 0.7,
    margin: '0 1em 1em auto',
    display: 'block'
  }
}))

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [isOpenDialog, setOpenDialog] = useState(false)
  const [email, setEmail] = useState('')
  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()
  const login = useLogin()
  const location = useLocation()

  const handleSubmit = (auth) => {
    setLoading(true)
    login(auth, location.state ? location.state.nextPathname : '/').catch(
      (error) => {
        setLoading(false)
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning'
        )
      }
    )
  }

  const handleReset = () => {
    setOpenDialog(true)
  }

  const validate = (values) => {
    const errors = {}
    if (!values.username) {
      errors.username = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit, values }) => {
          if (values.username !== email) {
            setEmail(values.username)
          }

          return (
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.main}>
                <Card className={classes.card}>
                  <div className={classes.avatar}>
                    <Avatar className={classes.icon}>
                      <LockIcon />
                    </Avatar>
                  </div>
                  <div className={classes.hint}>Welcome to PreciseBid</div>
                  <div className={classes.form}>
                    <div className={classes.input}>
                      <Field
                        autoFocus
                        name='username'
                        component={renderTextInput}
                        label={'Email'}
                        disabled={loading}
                      />
                    </div>
                    <div className={classes.input}>
                      <Field
                        name='password'
                        component={renderTextInput}
                        label={translate('ra.auth.password')}
                        type='password'
                        disabled={loading}
                      />
                    </div>
                    <Button
                      className={classes.resetButton}
                      onClick={handleReset}
                    >
                      reset password
                    </Button>
                  </div>
                  <CardActions className={classes.actions}>
                    <Button
                      variant='contained'
                      type='submit'
                      color='primary'
                      disabled={loading}
                      fullWidth
                    >
                      {loading && <CircularProgress size={25} thickness={2} />}
                      {translate('ra.auth.sign_in')}
                    </Button>
                  </CardActions>
                </Card>
                <Notification />
              </div>
            </form>
          )
        }}
      />
      <ResetPasswordDialog
        setter={setOpenDialog}
        isOpen={isOpenDialog}
        initial={{ email }}
      />
    </>
  )
}

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string
}

export default Login
