import useCommonStyles from '../../hooks/useCommonStyles'
import {
  Datagrid,
  DateField,
  EditButton,
  Pagination,
  ReferenceManyField,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  usePermissions
} from 'react-admin'
import Title from '../../elements/Title'
import { Typography } from '@material-ui/core'
import React from 'react'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import Change from '../../elements/Change'
import useConfig from '../../hooks/useConfig'

const EditWrapper = ({ show, ...props }) => {
  if (!show) return null
  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props, ...child.props })
    }
    return null
  })
  return <>{childrenWithProps}</>
}

const CustomToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label='Save' redirect={false} submitOnEnter={true} />
      <Button
        {...props}
        component={Link}
        to={{
          pathname: `/bids/create`,
          state: { record: { customerId: props.record.id } }
        }}
        style={{
          width: 'auto',
          margin: '0 20px'
        }}
        variant={'outlined'}
      >
        Create a BId
      </Button>
    </Toolbar>
  )
}

const CustomJobType = (props) => {
  return (
    <span>{props.record.jobType && props.jobTypes.find(jobType => props.record.jobType === jobType._id)
      ? props.jobTypes.find(jobType => props.record.jobType === jobType._id)?.name
      : ''}</span>
  )
}

const CustomerChange = (props) => {
  const classes = useCommonStyles()
  const { permissions: userId } = usePermissions()
  const config = useConfig()
  return (
    <Change
      {...props}
      title={props.id ? <Title {...props} source={'identity.name'} /> : null}
    >
      <SimpleForm toolbar={props.id ? <CustomToolbar /> : undefined}>
        <TextInput
          source='companyId'
          style={{ display: 'none' }}
          initialValue={userId}
        />
        <Typography variant='h6' gutterBottom>
          Identity
        </Typography>

        <TextInput
          source='identity.firstName'
          label='First Name'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='identity.lastName'
          label='Last Name'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='identity.email'
          label='Email'
          type='email'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='identity.phone'
          label='Phone number'
          type='tel'
          formClassName={classes.inlineBlock}
        />

        <Typography variant='h6' gutterBottom>
          Address
        </Typography>

        <TextInput
          source='address.address1'
          label='Address'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address.address2'
          label='Address (line 2)'
          formClassName={classes.inlineBlock}
        />
        <div />

        <TextInput
          source='address.city'
          label='City'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address.state'
          label='State'
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address.zip'
          label='Zip'
          formClassName={classes.inlineBlock}
        />

        <Typography variant='h6' gutterBottom>
          Notes
        </Typography>

        <TextInput source='notes' label='Notes' fullWidth />
        <SelectInput
          choices={config.leadSource || []}
          source='leadSource'
          label='Lead Source'
          fullWidth
          allowEmpty
        />

        <EditWrapper show={props.id}>
          <Typography variant='h6' gutterBottom>
            Bids
          </Typography>
          <ReferenceManyField
            addLabel={false}
            fullWidth
            reference='bids'
            target='customerId'
            filter={{ customerId: props.id }}
            perPage={5}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source='status' />
              <CustomJobType jobTypes={config.jobTypes} />
              <DateField source='createdAt' label='created At' showTime />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </EditWrapper>
      </SimpleForm>
    </Change>
  )
}
export default CustomerChange
