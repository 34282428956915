import useConfig from '../../../../hooks/useConfig'
import { useForm, useFormState } from 'react-final-form'
import { EBidElementTypes, EElementStatus } from '../../enums'
import client from '../../../../client/feathersClient'
import { useCallback, useEffect, useState } from 'react'
import { roundPrice } from '../../../../elements/PriceField'

const useUpdatePrices = () => {
  const [isLoading, setLoading] = useState(true)
  const config = useConfig()
  const { values } = useFormState()
  const form = useForm()

  useEffect(() => {
    if (config?.company?._id) {
      setLoading(false)
    }
  }, [config?.company?._id])

  const update = useCallback(async () => {
    setLoading(true)
    const genscoElements = values.elements.filter((element) =>
      [EBidElementTypes.equipment, EBidElementTypes.parts].includes(
        element.type
      )
    )

    let genscoItems
    try {
      const parts = await client.service('parts').find({
        query: {
          itemID: genscoElements.map((element) => element.itemID),
          $limit: 100000
        }
      })
      genscoItems = await client.service('gensco/integrate').find({
        query: {
          ids: parts.data.map((part) => part._id)
        }
      })
    } catch (e) {
      console.log('error: ', e)
      return
    } finally {
      setLoading(false)
    }

    const newElements = values.elements.map((element) => {
      let array = []
      let key = 'name'

      switch (element.type) {
        case EBidElementTypes.permits:
          array = config.permits
          break
        case EBidElementTypes.service:
          array = config.services
          break
        case EBidElementTypes.labor:
          array = config.labors
          break
        case EBidElementTypes.equipment:
        case EBidElementTypes.parts:
          key = 'itemID'
          array = genscoItems?.map((item) => {
            const markup =
              element.type === EBidElementTypes.parts
                ? config?.company?.config?.partsMarkup || 0
                : config?.company?.config?.equipMarkup || 0

            return {
              ...item,
              price: roundPrice((1 + markup / 100) * item.price, 0)
            }
          })
          break
        default:
          break
      }

      let status = EElementStatus.deleted
      let value = element.value

      const item = array.find((item) => {
        return item[key].toLowerCase() === element[key].toLowerCase()
      })

      if (item) {
        status = EElementStatus.modified
        value = item.value || item.price || value

        if (value === element.value) {
          status = undefined
        }
      }
      return {
        ...element,
        value,
        status
      }
    })

    form.change('elements', newElements)
  }, [values.elements, config?.company?._id])

  return [update, { isLoading }]
}

export default useUpdatePrices
