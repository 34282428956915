import React, { useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { useForm, useFormState } from 'react-final-form'
import useManageOptions from './useManageOptions'
import { Grid, IconButton } from '@material-ui/core'
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons'

export default function OptionDialog({ isOpen, setOpen }) {
  const form = useForm()
  const { values } = useFormState()
  const { options } = values
  const { removeOption, addOption } = useManageOptions()
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} fullScreen>
        <DialogTitle>Manage available options</DialogTitle>
        <DialogContent>
          {options.map((option, i) => {
            return (
              <Grid container spacing={2} key={option.id}>
                <Grid item xs={10}>
                  <TextField
                    style={{ width: '100%' }}
                    label={`Option ${i + 1}`}
                    value={option.name}
                    onChange={(event) => {
                      let copy = [...options]
                      copy.splice(i, 1, {
                        ...option,
                        name: event.target.value
                      })
                      form.change('options', copy)
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    color={'primary'}
                    onClick={() => removeOption(option.id)}
                    disabled={options.length <= 1}
                  >
                    <RemoveCircleOutline />
                  </IconButton>
                </Grid>
              </Grid>
            )
          })}
          <Button
            style={{
              margin: '30px 0'
            }}
            variant='outlined'
            color={'primary'}
            onClick={addOption}
            disabled={options.length > 5}
            endIcon={<AddCircleOutline />}
          >
            Add
          </Button>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose()
            }}
            color='primary'
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
