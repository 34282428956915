import { linkToRecord, TextField } from 'react-admin'
import { Link } from 'react-router-dom'
import React from 'react'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  }
}))

const TextLink = ({ linkTo, linkId, ...props }) => {
  const classes = useStyles()
  const link = linkToRecord(linkTo, get(props.record, linkId), 'edit')

  return (
    <Link to={link} className={classes.link}>
      <TextField {...props} />
    </Link>
  )
}

export default TextLink
