import {
  Datagrid,
  ShowButton,
  List,
  TextField,
  Show,
  BooleanField,
  SimpleShowLayout,
  NumberField,
  Filter,
  AutocompleteInput,
  ReferenceInput
} from 'react-admin'
import React from 'react'
import PriceField from '../../../elements/PriceField'

export const PartsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source='description' label='Description' fullWidth />
      <TextField
        source='extendedDescription'
        label='Extended Description'
        fullWidth
      />
      <NumberField source='quantity' />
      <PriceField source='price' />
      <TextField source='_id' />
      <TextField source='itemID' label={'item ID'} />
      <BooleanField source='isWebItem' />
      <BooleanField source='isRegularTemp' />
      <BooleanField source='isRestricted' />
      <TextField source='UoM' label={'UoM'} />
    </SimpleShowLayout>
  </Show>
)

export const Filters = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label={'Description'}
      source={'_id'}
      reference='parts'
      filterToQuery={(searchText) => {
        return (
          searchText && {
            'description[$regex]': searchText,
            'description[$options]': 'i'
          }
        )
      }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput
        allowEmpty
        optionText='description'
        emptyText='clear search'
      />
    </ReferenceInput>
    <ReferenceInput
      label={'Item ID'}
      source={'_id'}
      reference='parts'
      filterToQuery={(searchText) => {
        return (
          searchText && {
            'itemID[$regex]': searchText,
            'itemID[$options]': 'i'
          }
        )
      }}
      allowEmpty
      alwaysOn
    >
      <AutocompleteInput
        allowEmpty
        optionText='itemID'
        emptyText='clear search'
      />
    </ReferenceInput>
  </Filter>
)

export const PartsList = (props) => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<Filters />}
  >
    <Datagrid>
      <TextField source='itemID' label='itemID' />
      <TextField source='description' label='Description' />
      <TextField source='extendedDescription' label='Extended Description' />
      <PriceField source='price' label='Price' />
      <TextField source='UoM' label={'UoM'} />
      <ShowButton />
    </Datagrid>
  </List>
)
