import React from 'react'
import { useFormState } from 'react-final-form'

import { NumberInput, required } from 'react-admin'
import { Card, CardContent, Grid, Typography, Divider } from '@material-ui/core'
import useStyles from './useStyles'
import Element from './Element'
import { formatPrice } from '../../../../elements/PriceField'
import { EDiscountKind } from '../../enums'
import Header from './Header'
import useSubtotals from './counting/useSubtotals'
import useTotals from './counting/useTotals'
import useItemSums from './counting/useItemSums'
import ElementsWrapper from './ElementsWrapper'

const Quote = ({ isTemplate }) => {
  const styles = useStyles()

  const { values } = useFormState()
  const {
    salesTax,
    downPayment,
    elements = [],
    modifiers = [],
    subtotals = {},
    totals = {},
    itemSums = {},
    options,
    loan
  } = values

  useItemSums()
  useSubtotals()
  useTotals()

  return (
    <>
      <Card className={styles.card}>
        <CardContent>
          <Header />

          <ElementsWrapper label={'Items'}>
            {elements.map((element, index) => {
              return <Element index={index} key={index} />
            })}
          </ElementsWrapper>

          <Divider style={{ margin: '30px 0' }} />

          <Grid container spacing={2} wrap={'nowrap'} justify={'flex-end'}>
            <Grid item md={5} className={styles.flex}>
              <Typography className={styles.secondaryTitle}>
                {'Items Subtotal:'}
              </Typography>
            </Grid>
            <div className={styles.bigBreak} />
            {options.map((option) => {
              return (
                <Typography className={styles.smallColumn} key={option.id}>
                  {formatPrice(itemSums[option.id])}
                </Typography>
              )
            })}
            <div className={styles.smallBreak} />
          </Grid>

          <Divider style={{ margin: '30px 0' }} />

          <ElementsWrapper label={'Pre tax'} variant={'red'}>
            {modifiers.map((modifier, index) => {
              if (modifier.kind === EDiscountKind.post) return null
              return <Element index={index} key={index} isModifier />
            })}
          </ElementsWrapper>

          <Divider style={{ margin: '30px 0' }} />

          <Grid container spacing={2} wrap={'nowrap'} justify={'flex-end'}>
            <Grid item md={5} className={styles.flex}>
              <Typography className={styles.secondaryTitle}>
                {'Sub Total:'}
              </Typography>
            </Grid>
            <div className={styles.bigBreak} />
            {options.map((option) => {
              return (
                <Typography className={styles.smallColumn} key={option.id}>
                  {formatPrice(subtotals[option.id])}
                </Typography>
              )
            })}
            <div className={styles.smallBreak} />
          </Grid>

          <Divider style={{ margin: '30px 0' }} />

          <Grid container spacing={2} wrap={'nowrap'} justify={'flex-end'}>
            <Grid item md={5} className={styles.taxInput}>
              <NumberInput
                min={0}
                step={1}
                source='salesTax'
                label='Sales Tax [%]'
                variant={'standard'}
                validate={!isTemplate && required()}
              />
            </Grid>
            <div className={styles.bigBreak} />
            {options.map((option) => {
              return (
                <Typography className={styles.smallColumn} key={option.id}>
                  {formatPrice((subtotals[option.id] * salesTax) / 100)}
                </Typography>
              )
            })}
            <div className={styles.smallBreak} />
          </Grid>

          <Divider style={{ margin: '30px 0' }} />

          <ElementsWrapper label={'Post tax'} variant={'blue'}>
            {modifiers.map((element, index) => {
              if (element.kind !== EDiscountKind.post) return null
              return <Element index={index} key={index} isModifier />
            })}
          </ElementsWrapper>

          <Divider style={{ margin: '30px 0' }} />

          <Grid container spacing={2} wrap={'nowrap'} justify={'flex-end'}>
            <Grid item md={5} className={styles.flex}>
              <Typography className={styles.secondaryTitle}>
                {'Total:'}
              </Typography>
            </Grid>
            <div className={styles.bigBreak} />
            {options.map((option) => {
              return (
                <Typography className={styles.smallColumn} key={option.id}>
                  {formatPrice(totals[option.id])}
                </Typography>
              )
            })}
            <div className={styles.smallBreak} />
          </Grid>

          <Divider style={{ margin: '30px 0' }} />

          <Grid container spacing={2} wrap={'nowrap'} justify={'flex-end'}>
            <Grid item md={5} className={styles.taxInput}>
              <NumberInput
                min={0}
                step={1}
                source='downPayment'
                label='Down Payment [%]'
                variant={'standard'}
              />
            </Grid>
            <div className={styles.bigBreak} />
            {options.map((option) => {
              return (
                <Typography className={styles.smallColumn} key={option.id}>
                  {formatPrice((totals[option.id] * downPayment) / 100)}
                </Typography>
              )
            })}
            <div className={styles.smallBreak} />
          </Grid>

          <Divider style={{ margin: '30px 0' }} />

          <Grid container spacing={2} wrap={'nowrap'} justify={'flex-end'}>
            <Grid item md={3} className={styles.flex} alignItems={'center'}>
              <Typography className={styles.secondaryTitle}>
                {'Loan Calculator:'}
              </Typography>
            </Grid>
            <Grid item md={3} className={styles.taxInput}>
              <NumberInput
                min={0}
                step={1}
                source='loan.months'
                label='Months'
                variant={'standard'}
              />
            </Grid>
            <Grid item md={3} className={styles.taxInput}>
              <NumberInput
                min={0}
                step={1}
                source='loan.rate'
                label='Interest Rate [%]'
                variant={'standard'}
              />
            </Grid>
            {options.map((option) => {
              let P = 0
              const rate = (loan?.rate || 0) / 12 / 100
              const months = loan?.months || 1
              if (rate) {
                const D =
                  ((1 + rate) ** months - 1) / (rate * (1 + rate) ** months)
                P = totals[option.id] / D
              } else {
                P = totals[option.id] / months
              }

              return (
                <Typography className={styles.smallColumn} key={option.id}>
                  {formatPrice(P)}
                </Typography>
              )
            })}
            <div className={styles.smallBreak} />
          </Grid>

          <Divider style={{ margin: '30px 0' }} />

          <Grid container spacing={2} wrap={'nowrap'} justify={'flex-end'}>
            <Grid item md={5} className={styles.flex}>
              <Typography className={styles.secondaryTitle}>
                {'Additional cost:'}
              </Typography>
            </Grid>
            <div className={styles.bigBreak} />
            {options.map((option, index) => {
              if (!index) {
                return <div className={styles.smallColumn} key={option.id} />
              }
              return (
                <Typography className={styles.smallColumn} key={option.id}>
                  {formatPrice(totals[option.id] - totals[options[0].id])}
                </Typography>
              )
            })}
            <div className={styles.smallBreak} />
          </Grid>
        </CardContent>
      </Card>

      {/* Mobile Section */}

      <div className={styles.mobileContainer}>
        <Card className={styles.mobileCard}>
          <CardContent className={styles.alignCenter}>
            {[...elements, ...modifiers].map((element, index) => {
              if (element.kind === EDiscountKind.post) return null
              const isDivider = elements.find(
                (e, elIndex) =>
                  element.kind !== EDiscountKind.post && elIndex > index
              )
              return (
                <React.Fragment key={index}>
                  <Element index={index} isModifier={element.kind} />
                  {isDivider && (
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  )}
                </React.Fragment>
              )
            })}
          </CardContent>
        </Card>
        <Card className={styles.mobileCard}>
          <CardContent className={styles.alignCenter}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={styles.boldTitle}>
                  {'Sub Total:'}
                </Typography>
              </Grid>
              {options.map((option, index) => {
                return (
                  <Grid item xs={12} key={index} className={styles.spacedRow}>
                    <Typography>
                      <b>{`${option.name}:`}</b>
                    </Typography>
                    <Typography>{formatPrice(subtotals[option.id])}</Typography>
                  </Grid>
                )
              })}
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={12} item>
                <Divider style={{ marginTop: '24px' }} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} className={styles.alignCenter}>
                <NumberInput
                  min={0}
                  step={1}
                  source='salesTax'
                  label='Sales Tax [%]'
                  variant={'standard'}
                />
              </Grid>
              {options.map((option, index) => {
                return (
                  <Grid item xs={12} key={index} className={styles.spacedRow}>
                    <Typography>
                      <b>{`${option.name}:`}</b>
                    </Typography>
                    <Typography>
                      {formatPrice((subtotals[option.id] * salesTax) / 100)}
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
          </CardContent>
        </Card>
        <Card className={styles.mobileCard}>
          <CardContent className={styles.alignCenter}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={styles.boldTitle}>
                  {'After Tax Discounts:'}
                </Typography>
              </Grid>
              {modifiers.map((element, index) => {
                if (element.kind !== EDiscountKind.post) return null
                const isDivider = elements.find(
                  (e, elIndex) =>
                    element.discount === EDiscountKind.post && elIndex > index
                )
                return (
                  <>
                    <Element index={index} key={index} isModifier />
                    {isDivider && (
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    )}
                  </>
                )
              })}
            </Grid>
          </CardContent>
        </Card>
        <Card className={styles.mobileCard}>
          <CardContent className={styles.alignCenter}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={styles.boldTitle}>{'Total:'}</Typography>
              </Grid>
              {options.map((option, index) => {
                return (
                  <Grid item xs={12} key={index} className={styles.spacedRow}>
                    <Typography>
                      <b>{`${option.name}:`}</b>
                    </Typography>
                    <Typography>{formatPrice(totals[option.id])}</Typography>
                  </Grid>
                )
              })}
            </Grid>
          </CardContent>
        </Card>
        <Card className={styles.mobileCard}>
          <CardContent className={styles.alignCenter}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={styles.boldTitle}>
                  {'Additional cost:'}
                </Typography>
              </Grid>
              {options.map((option, index) => {
                if (!index) return
                return (
                  <Grid item xs={12} key={index} className={styles.spacedRow}>
                    <Typography>
                      <b>{`${option.name}:`}</b>
                    </Typography>
                    <Typography>
                      {formatPrice(totals[option.id] - totals[options[0].id])}
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  )
}

Quote.propTypes = {}

export default Quote
