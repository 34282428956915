import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  TextField
} from 'react-admin'
import React from 'react'
import DateFilters from '../../elements/DateFilters'

export const Filters = (props) => <Filter {...props}>{DateFilters}</Filter>

const BidTemplateList = (props) => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<Filters />}
    title='Bid Templates'
  >
    <Datagrid>
      <TextField source='name' />

      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)

export default BidTemplateList
