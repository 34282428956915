import {
  BACKGROUND_FETCH_ERROR,
  BACKGROUND_FETCH_ERROR_2,
  BACKGROUND_FETCH_PRICE,
  BLOCK_ROUTER
} from './types'

const reducer = (state = {}, { type, payload }) => {
  if (type === BLOCK_ROUTER) {
    return {
      ...state,
      blockRouter: payload
    }
  }
  if (type === BACKGROUND_FETCH_PRICE) {
    return {
      ...state,
      isBackgroundFetchPrice: payload
    }
  }
  if (type === BACKGROUND_FETCH_ERROR) {
    return {
      ...state,
      isBackgroundFetchError: payload
    }
  }
  if (type === BACKGROUND_FETCH_ERROR_2) {
    return {
      ...state,
      isBackgroundFetchError2: payload
    }
  }
  return state
}

export default reducer
