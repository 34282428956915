import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
  FormTab
} from 'react-admin'
import React from 'react'
import useCommonStyles from '../../../hooks/useCommonStyles'

const PermitsTab = (props) => {
  const classes = useCommonStyles()
  return (
    <FormTab label={'Permits'} {...props}>
      <ArrayInput source='permits'>
        <SimpleFormIterator>
          <TextInput
            source='name'
            label={'Name'}
            formClassName={classes.inlineBlock}
            validate={required()}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </FormTab>
  )
}

export default PermitsTab
