import React, { useEffect, useState } from 'react'
import { Field } from 'react-final-form'
import { Typography, IconButton } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  text: {
    background: 'rgba(0, 0, 0, 0.04)',
    cursor: 'pointer',
    width: '236px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'rgba(0, 0, 0, 0.54)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    margin: '10px 0 !important',
    padding: '10px 10px'
  },
  img: {
    margin: '0.5rem',
    maxHeight: '10rem',
    cursor: 'pointer'
  }
}))

const Input = ({ widget, formProps, props, value }) => {
  const styles = useStyles()

  useEffect(() => {
    if (value) {
      formProps.input.onChange(value)
    }
  }, [value])

  return (
    <div>
      {formProps.input.value ? (
        <>
          <img
            className={styles.img}
            onClick={props.editable && widget.open}
            src={formProps.input.value}
            alt={props.name}
          />
          {!props.editable ? null : (
            <IconButton onClick={() => formProps.input.onChange(undefined)}>
              <RemoveIcon />
            </IconButton>
          )}
        </>
      ) : (
        <Typography className={styles.text} onClick={widget.open}>
          {`Upload Image ${props.required ? '*' : ''}`}
          <AddIcon color='inherit' />
        </Typography>
      )}
    </div>
  )
}

const ImageInput = (props) => {
  const [widget, setWidget] = useState({})
  const [value, setValue] = useState()

  useEffect(() => {
    const widget = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUD_NAME,
        uploadPreset: process.env.REACT_APP_UPLOAD_PRESET,
        fieldName: props.source,
        cropping: 'server',
        sources: ['local', 'url', 'camera']
      },
      (error, result) => {
        if (result && result.event === 'success') {
          const baseUrl = `https://res.cloudinary.com/${process.env.REACT_APP_CLOUD_NAME}/image/upload`
          let source
          if (result.info.coordinates) {
            const coords = result.info.coordinates.custom[0]
            source = `${baseUrl}/x_${coords[0]},y_${coords[1]},c_crop,w_${coords[2]},h_${coords[3]}/${result.info.public_id}`
          } else {
            source = `${baseUrl}/${result.info.public_id}`
          }
          setValue(source)
        }
        if (error) {
          console.log(error)
        }
      }
    )
    setWidget(widget)
  }, [value])

  return (
    <Field name={props.source}>
      {(formProps) => {
        return (
          <Input
            formProps={formProps}
            props={props}
            widget={widget}
            value={value}
          />
        )
      }}
    </Field>
  )
}

export default ImageInput
