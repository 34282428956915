import React from 'react'
import feathersClient from './client/feathersClient'
import { authClient, restClient } from 'ra-data-feathers'
import { Admin, Resource } from 'react-admin'
import PeopleIcon from '@material-ui/icons/People'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import DescriptionIcon from '@material-ui/icons/Description'
import BuildIcon from '@material-ui/icons/Build'

import CustomerList from './models/customers/List'
import CustomLayout from './layout'
import CompanyEdit from './models/companies/Edit'
import BidChange from './models/bids/Change'
import BidList from './models/bids/List'
import BidTemplateChange from './models/bidTemplate/Change'
import BidTemplateList from './models/bidTemplate/List'
import LogoutButton from './layout/LogoutButton'
import LoginPage from './layout/Login'
import Dashboard from './layout/Dashbord'
import { PartsList, PartsShow } from './models/gensco/parts'
import CustomerChange from './models/customers/Change'
import reducer from './reducer'

const restClientOptions = {
  shortId: 'id',
  id: '_id', // In this example, the database uses '_id' rather than 'id'
  usePatch: true // Use PATCH instead of PUT for updates
}

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: {
    // Options included in calls to Feathers client.authenticate
    strategy: 'local' // The authentication strategy Feathers should use
  },
  permissionsField: 'sub',
  permissionsKey: 'userId',
  passwordField: 'password', // The key used to provide the password to Feathers client.authenticate
  usernameField: 'email', // Our example database might use 'username' rather than 'email'
  redirectTo: '/login'
}

const App = () => {
  return (
    <Admin
      dataProvider={restClient(feathersClient, restClientOptions)}
      authProvider={authClient(feathersClient, authClientOptions)}
      layout={CustomLayout}
      logoutButton={LogoutButton}
      loginPage={LoginPage}
      dashboard={Dashboard}
      customReducers={{ app: reducer }}
    >
      <Resource name={'users'} />
      <Resource name={'generate'} />
      <Resource name={'companies'} edit={CompanyEdit} />
      <Resource
        name='customers'
        icon={PeopleIcon}
        list={CustomerList}
        create={CustomerChange}
        edit={CustomerChange}
      />
      <Resource
        name='bids'
        icon={AttachMoneyIcon}
        list={BidList}
        create={BidChange}
        edit={BidChange}
      />
      <Resource
        name='bidTemplates'
        primaryText={'test'}
        option={{
          primaryText: 'Bid Templates'
        }}
        icon={DescriptionIcon}
        list={BidTemplateList}
        create={BidTemplateChange}
        edit={BidTemplateChange}
      />
      <Resource
        name={'parts'}
        list={PartsList}
        show={PartsShow}
        icon={BuildIcon}
      />
    </Admin>
  )
}

export default App
