import { makeStyles } from '@material-ui/core/styles'
import { EElementStatus } from '../../../enums'
import { orange, red } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap'
    }
  },
  flex: { display: 'flex' },
  labelColumn: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      flexBasis: '100%',
      width: '100%',
      padding: '16px 4px',
      boxSizing: 'border-box'
    }
  },
  smallColumn: {
    display: 'flex',
    width: '100%',
    padding: '16px 4px',
    boxSizing: 'border-box',
    [theme.breakpoints.up('lg')]: {
      width: '100px',
      margin: '0 12px',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  qtyInput: {
    alignSelf: 'center',
    justifySelf: 'center',
    width: '50px',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      margin: '0'
    }
  },
  title: {
    alignSelf: 'center',
    justifySelf: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      margin: '0 8px'
    }
  },
  itemTitle: {
    alignSelf: 'center',
    justifySelf: 'center',
    margin: '0 auto 0 6px',
    [theme.breakpoints.down('md')]: {
      margin: '0 8px'
    }
  },
  list: {
    width: '100%'
  },
  itemList: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 4px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    }
  },
  optionMobile: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  label: {
    display: 'block',
    margin: ' auto auto auto 8px',
    fontWeight: '700',
    alignSelf: 'flex-start',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  buttonContainer: {
    alignSelf: 'center',
    width: '112px',
    padding: '0 8px',
    [theme.breakpoints.down('lg')]: {
      display: 'flex'
    }
  },
  divider: {
    margin: '20px 0',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  [EElementStatus.modified]: {
    color: orange[500]
  },
  [EElementStatus.deleted]: {
    color: red[500]
  }
}))

export default useStyles
