import { useEffect } from 'react'
import { useForm } from 'react-final-form'

const useTemplate = (template, setTemplate) => {
  const form = useForm()

  useEffect(() => {
    if (template?.id) {
      form.change('options', null)
      form.change('salesTax', null)
      form.change('elements', [])

      Object.keys(template).forEach((key) => {
        form.change(key, template[key])
      })
    }
    setTemplate && setTemplate(undefined)
  }, [template?.id, setTemplate])
}

export default useTemplate
