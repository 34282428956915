import { useForm, useFormState } from 'react-final-form'
import { useEffect } from 'react'
import { roundPrice } from '../../../../../elements/PriceField'

const useItemSums = () => {
  const form = useForm()
  const { values } = useFormState()
  const { elements, options } = values

  useEffect(() => {
    const itemSums = {}
    options.forEach((option) => {
      itemSums[option.id] = 0
    })

    if (!elements) return
    elements.forEach((element) => {
      options.forEach((option) => {
        const elIndex = element.options.findIndex((elId) => elId === option.id)
        if (elIndex >= 0) {
          itemSums[option.id] += element.value * (element.qty || 1)
        }
      })
    })

    Object.keys(itemSums).forEach(
      (key) => (itemSums[key] = roundPrice(itemSums[key]))
    )

    form.change('itemSums', itemSums)
  }, [elements, options])
}

export default useItemSums
