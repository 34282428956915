import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none'
  },
  manageButton: {
    display: 'block',
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  marginVertical: {
    margin: '30px 0'
  },
  autocomplete: {
    width: '100%'
  },
  shortInput: {
    width: '150px',
    paddingRight: '16px'
  },
  button: {
    height: 'min-content',
    marginBottom: '19px'
  }
}))

export default useStyles
