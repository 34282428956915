import * as React from 'react'
import Button from '@material-ui/core/Button'
import { TopToolbar } from 'react-admin'
import Papa from 'papaparse'
import { saveAs } from 'file-saver'
import { useGetOne } from 'react-admin'
import format from 'date-fns/format'
import { EBidElementTypes } from './enums'

const BidActions = ({ data }) => {
  const { data: customer, loaded } = useGetOne('customers', data?.customerId)

  return (
    <TopToolbar>
      <Button
        style={{ margin: '0 8px' }}
        onClick={async () => {
          window.open(
            `${process.env.REACT_APP_API_URL}/generate/offer/${data.id}`
          )
        }}
      >
        Generate Offer PDF
      </Button>
      <Button
        style={{ margin: '0 8px' }}
        onClick={async () => {
          window.open(
            `${process.env.REACT_APP_API_URL}/generate/proposal/${data.id}`
          )
        }}
      >
        Generate Proposal PDF
      </Button>
      <Button
        onClick={async () => {
          const elements = data?.elements
            .filter(
              (el) =>
                [EBidElementTypes.parts, EBidElementTypes.equipment].includes(
                  el.type
                ) && el.options.includes(data?.selectedOption)
            )
            .map((el) => ({
              name: el.itemID || el.name,
              fill: 0,
              qty: el.qty
            }))

          const now = format(new Date(), 'yyyy-MM-dd')
          const fileString = Papa.unparse(elements, { header: false })
          const blob = new Blob([fileString])
          saveAs(blob, `${customer.identity.lastName}_${now}.csv`)
        }}
        disabled={!loaded || !data?.selectedOption}
        variant={'contained'}
      >
        Parts/Equipment CSV
      </Button>
    </TopToolbar>
  )
}

export default BidActions
