import React, { useEffect, useState } from 'react'

import { useForm } from 'react-final-form'
import { SelectInput } from 'react-admin'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

const ConnectedSelect = ({ from, ...rest }) => {
  const form = useForm()
  const [values, setValues] = useState()
  const unsubscribe = form.subscribe(
    (formState) => {
      if (!isEqual(values, formState.values)) {
        setValues(formState.values)
      }
    },
    {
      values: true
    }
  )

  useEffect(() => {
    return unsubscribe()
  }, [])

  return <SelectInput choices={get(values, from)} {...rest} />
}

export default ConnectedSelect
