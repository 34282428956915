import { EBidElementTypes } from '../../enums'
import AsyncAutocomplete from '../../../../elements/AsyncAutocomplete'
import React from 'react'
import { AutocompleteInput, LinearProgress } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'

const RaAutocomplete = withStyles({
  container: {
    width: 'auto',
    flexGrow: 0,
    marginRight: '16px'
  }
})(AutocompleteInput)

const Autocomplete = ({ choices = [], company, form, type, ...props }) => {
  if (!company?._id) {
    return <LinearProgress fullWidth {...props} style={{ minWidth: '200px' }} />
  }

  if (!choices.length)
    return (
      <AsyncAutocomplete
        source={'item'}
        serviceKeys={['description', 'itemID']}
        service={'parts'}
        onChange={(selected) => {
          if (!selected) {
            form.change('value', undefined)
            return
          }
          const markup =
            type === EBidElementTypes.parts
              ? company?.config?.partsMarkup || 0
              : company?.config?.equipMarkup || 0

          const name = `${selected.itemID} - ${selected.description}`
          const value = Number((selected.price * (1 + markup / 100)).toFixed(0))
          form.change('item', name)
          form.change('itemID', selected.itemID)
          form.change('value', Number(value))
        }}
        fullWidth
        {...props}
      />
    )

  // demo
  return (
    <RaAutocomplete
      source={'item'}
      choices={choices}
      onChange={(value) => {
        const selected = choices.find((eqp) => eqp.id === value)
        if (!selected) return

        form.change('item', selected.name)
        form.change('value', selected.value)
      }}
      style={{
        flexGrow: 0,
        marginRight: '16px'
      }}
      {...props}
    />
  )
}

export default Autocomplete
