import { useForm, useFormState } from 'react-final-form'
import { useEffect } from 'react'
import { roundPrice } from '../../../../../elements/PriceField'
import { EDiscountKind, EDiscountType, EDiscountUnit } from '../../../enums'

const useSubtotals = () => {
  const form = useForm()
  const { values } = useFormState()
  const { options, modifiers, itemSums } = values

  useEffect(() => {
    const subtotals = { ...itemSums }

    if (!itemSums || !Object.keys(itemSums).length) return
    if (!modifiers) {
      form.change('subtotals', subtotals)
      return
    }

    modifiers.forEach((modifier) => {
      options.forEach((option) => {
        const elIndex = modifier.options.findIndex((elId) => elId === option.id)
        if (elIndex >= 0 && modifier.kind === EDiscountKind.pre) {
          const value =
            modifier.unit === EDiscountUnit.dollar
              ? modifier.value
              : roundPrice((modifier.value * itemSums[option.id]) / 100)

          subtotals[option.id] +=
            value * (modifier.type === EDiscountType.discount ? -1 : 1)

          if (!modifier.dollarValue) {
            modifier.dollarValue = {}
          }
          modifier.dollarValue[option.id] = value
        }
      })
    })

    Object.keys(subtotals).forEach(
      (key) => (subtotals[key] = roundPrice(subtotals[key]))
    )

    form.change('subtotals', subtotals)
  }, [modifiers, options, itemSums])
}

export default useSubtotals
