import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Divider, Typography } from '@material-ui/core'
import { useForm, useFormState } from 'react-final-form'
import { ArrayInput, NumberInput, SelectInput, TextInput } from 'react-admin'
import PriceInput from '../../../../elements/PriceInput'
import useType from '../useType'
import {
  bidElementData,
  EBidElementTypes,
  EDiscountType,
  EDiscountKind,
  EDiscountUnit
} from '../../enums'
import useConfig from '../../../../hooks/useConfig'
import useManageOptions from '../Quote/useManageOptions'
import OptionsDialog from '../Quote/OptionsDialog'
import useTemplate from './useTemplate'
import useStyles from './useStyles'
import Autocomplete from './Autocomplete'
import Row from '../Row'
import { roundPrice } from '../../../../elements/PriceField'

import { v4 as uuidv4 } from 'uuid'
import useUpdatePrices from './useUpdatePrices'
import { useSelector } from 'react-redux'

const initialOptions = ['option1', 'option2', 'option3'].map((name) => ({
  id: uuidv4(),
  name: name
}))

const QuoteController = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const { type } = values
  const styles = useStyles()
  const [update, { isLoading }] = useUpdatePrices()
  const isFetchingPrice = useSelector(
    (state) => state.app.isBackgroundFetchPrice
  )
  const isFetchingError = useSelector(
    (state) => state.app.isBackgroundFetchError
  )
  const isFetchingError2 = useSelector(
    (state) => state.app.isBackgroundFetchError2
  )

  const helperText = useMemo(() => {
    if (isFetchingError) {
      return 'Sorry, there was a problem communicating with Gensco.com. Please remove this item and try again.'
    }
    if (isFetchingError2) {
      return 'Sorry, there was a problem. Please try again.'
    }
    if (isFetchingPrice) {
      return 'updating price...'
    }
    return undefined
  }, [isFetchingError, isFetchingError2, isFetchingPrice])

  const choices = useType(type)
  const { services, labors, company, permits } = useConfig()
  const { setDialogOpen, isDialogOpen } = useManageOptions()
  useTemplate(props.template, props.setTemplate)

  useEffect(() => {
    form.change('item', null)
    form.change('itemId', null)
    form.change('configItem', null)
    form.change('value', null)
    form.change('qty', 1)
  }, [type])

  return (
    <>
      <ArrayInput source='elements' className={styles.hide}>
        <div />
      </ArrayInput>
      <ArrayInput source='modifiers' className={styles.hide}>
        <div />
      </ArrayInput>
      <ArrayInput
        source='options'
        className={styles.hide}
        initialValue={initialOptions}
      >
        <div />
      </ArrayInput>
      <Row
        columnMobile
        wrapperStyle={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'wrap'
        }}
      >
        <SelectInput
          source={'type'}
          choices={bidElementData}
          style={{ marginRight: '16px' }}
          {...props}
        />
        {type ? (
          <>
            <>
              {[EBidElementTypes.miscellaneous].includes(type) ? (
                <TextInput
                  source={'item'}
                  style={{ marginRight: '16px' }}
                  {...props}
                />
              ) : [EBidElementTypes.equipment, EBidElementTypes.parts].includes(
                  type
                ) ? (
                <Autocomplete
                  type={type}
                  choices={choices}
                  company={company}
                  form={form}
                  style={{ marginRight: '16px' }}
                  {...props}
                />
              ) : (
                <SelectInput
                  source={'configItem'}
                  label={'item'}
                  choices={choices}
                  onChange={(value) => {
                    let obj
                    if (
                      [
                        EBidElementTypes.service,
                        EBidElementTypes.labor,
                        EBidElementTypes.permits
                      ].includes(type)
                    ) {
                      obj = services.find((s) => s._id === value.target.value)
                      if (!obj) {
                        obj = labors.find(
                          (labor) => labor._id === value.target.value
                        )
                      }
                      if (!obj) {
                        obj = permits.find(
                          (permit) => permit._id === value.target.value
                        )
                      }
                    }
                    form.change('item', obj?.name || value.target.value)
                    form.change('value', obj?.value || 0)
                  }}
                  style={{ marginRight: '16px' }}
                  {...props}
                />
              )}

              <NumberInput
                source='qty'
                min={0}
                step={1}
                defaultValue={1}
                {...props}
                className={styles.shortInput}
              />
              <PriceInput
                source='value'
                defaultValue={0}
                {...props}
                className={styles.shortInput}
                helperText={helperText}
              />
            </>
            <Button
              variant='contained'
              color='primary'
              className={styles.button}
              onClick={() => {
                const prev = [...(values.elements || [])]
                form.change('elements', [
                  ...prev,
                  {
                    name: values.item,
                    value: roundPrice(values.value),
                    qty: values.qty,
                    itemID: [
                      EBidElementTypes.equipment,
                      EBidElementTypes.parts
                    ].includes(type)
                      ? values.itemID
                      : undefined,
                    type: type,
                    options: values.options.map((option) => option.id)
                  }
                ])
              }}
              disabled={
                !values.item ||
                !(values.value && values.value >= 0) ||
                !(values.qty && Number(values.qty) >= 0) ||
                !values.type ||
                isFetchingPrice
              }
            >
              Add to Quote
            </Button>

            <Divider className={styles.marginVertical} />
          </>
        ) : (
          <></>
        )}
      </Row>

      <Typography variant='h6' gutterBottom>
        {'Fees / Discount'}
      </Typography>
      <Row
        columnMobile
        wrapperStyle={{
          justifyContent: 'flex-start',
          alignItems: 'center'
        }}
      >
        <TextInput
          source='modifier.name'
          label={'Name'}
          style={{ marginRight: '16px' }}
          {...props}
        />
        <SelectInput
          source={'modifier.kind'}
          label={'Kind'}
          choices={Object.keys(EDiscountKind).map((key) => ({
            id: key,
            name: `${key}-tax`
          }))}
          {...props}
          className={styles.shortInput}
        />
        <SelectInput
          source={'modifier.type'}
          label={'Type'}
          choices={Object.keys(EDiscountType).map((key) => ({
            id: key,
            name: `${key}`
          }))}
          {...props}
          className={styles.shortInput}
        />
        <SelectInput
          source={'modifier.unit'}
          label={'Unit'}
          choices={Object.keys(EDiscountUnit).map((key) => ({
            id: key,
            name: EDiscountUnit[key]
          }))}
          onChange={() => {
            form.change('modifier.value', null)
          }}
          {...props}
          className={styles.shortInput}
        />
        {values.modifier?.unit === EDiscountUnit.dollar ? (
          <PriceInput
            source='modifier.value'
            label={'Amount'}
            {...props}
            className={styles.shortInput}
          />
        ) : (
          <NumberInput
            source='modifier.value'
            label={'Amount'}
            max={100}
            min={0}
            {...props}
            className={styles.shortInput}
          />
        )}

        <Button
          variant='contained'
          color='primary'
          className={styles.button}
          onClick={() => {
            const prev = [...(values.modifiers || [])]

            form.change('modifiers', [
              ...prev,
              {
                name: values.modifier?.name,
                value: roundPrice(values.modifier?.value),
                options: values.options.map((option) => option.id),
                unit: values.modifier?.unit,
                kind: values.modifier?.kind,
                type: values.modifier?.type
              }
            ])
            form.change('modifier', {})
          }}
          disabled={
            !values.modifier?.name ||
            !values.modifier?.value ||
            values.modifier?.value < 0 ||
            !values.modifier?.unit ||
            !values.modifier?.type
          }
        >
          Add
        </Button>
      </Row>

      <Row>
        <Button
          variant='outlined'
          color='primary'
          onClick={update}
          disabled={isLoading}
        >
          Update Prices
        </Button>
      </Row>

      <Button
        variant='outlined'
        color='primary'
        onClick={() => setDialogOpen(true)}
        className={styles.manageButton}
      >
        Manage Options
      </Button>
      <OptionsDialog isOpen={isDialogOpen} setOpen={setDialogOpen} />
    </>
  )
}

QuoteController.propTypes = {
  template: PropTypes.object,
  setTemplate: PropTypes.func
}

export default QuoteController
