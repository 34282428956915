import { useMemo } from 'react'

const useDemo = (markup = 0) => {
  return useMemo(() => {
    const data = [
      {
        shortId: 'prd1123',
        name: 'Mitsubishi Indoor Wall Mounted Unit 14',
        price: 100000,
        inStock: 'YES',
        description: 'product description',
        manufacturer: 'Mitsubishi'
      },
      {
        shortId: 'prd1124',
        name: 'Mitsubishi Indoor Wall Mounted Unit 16',
        price: 120000,
        inStock: 'YES',
        description: 'product description',
        manufacturer: 'Mitsubishi'
      },
      {
        shortId: 'prd1125',
        name: 'Mitsubishi Indoor Wall Mounted Unit 30',
        price: 150000,
        inStock: 'YES',
        description: 'product description',
        manufacturer: 'Mitsubishi'
      },
      {
        shortId: 'prd1126',
        name: 'Mitsubishi Outdoor Wall Mounted Unit 14',
        price: 100000,
        inStock: 'NO',
        description: 'product description',
        manufacturer: 'Mitsubishi'
      },
      {
        shortId: 'prd1127',
        name: 'Ruud Outdoor Wall Mounted Unit 16',
        price: 150000,
        inStock: 'YES',
        description: 'product description',
        manufacturer: 'Ruud'
      },
      {
        shortId: 'prd1128',
        name: 'Ruud Outdoor Wall Mounted Unit 30',
        price: 200000,
        inStock: 'YES',
        description: 'product description',
        manufacturer: 'Ruud'
      },
      {
        shortId: 'prd1129',
        name: 'Ruud Indoor Wall Mounted Unit 14',
        price: 90000,
        inStock: 'NO',
        description: 'product description',
        manufacturer: 'Ruud'
      },
      {
        shortId: 'prd1130',
        name: 'Ruud Indoor Wall Mounted Unit 16',
        price: 130000,
        inStock: 'YES',
        description: 'product description',
        manufacturer: 'Ruud'
      },
      {
        shortId: 'prd1131',
        name: 'Ruud Indoor Wall Mounted Unit 30',
        price: 160000,
        inStock: 'YES',
        description: 'product description',
        manufacturer: 'Ruud'
      },
      {
        shortId: 'prd1132',
        name: 'Line Hide - 3 foot',
        price: 1000,
        inStock: 'NO',
        description: 'product description'
      },
      {
        shortId: 'prd1133',
        name: 'Line Hide - Right Angle',
        price: 1000,
        inStock: 'YES',
        description: 'product description'
      },
      {
        shortId: 'prd1134',
        name: 'filter',
        price: 1000,
        inStock: 'YES',
        description: 'product description'
      },
      {
        shortId: 'prd1135',
        name: 'Wall Penetration Cover',
        price: 500,
        inStock: 'NO',
        description: 'product description'
      }
    ]
    return data.map((el) => ({
      ...el,
      id: el.shortId,
      value: el.price * Number((1 + markup / 100).toFixed(2))
    }))
  }, [markup])
}

export default useDemo
