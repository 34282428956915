import { useEffect, useState } from 'react'
import { subDays, endOfDay, startOfDay } from 'date-fns'
import app from '../../../client/feathersClient'

const useData = (from, to, range, type) => {
  const [isLoading, setLoading] = useState(false)
  useEffect(() => {
    if (!to) {
      to = endOfDay(new Date())
    }
    if (!from) {
      from = startOfDay(subDays(to, 7))
    }
  }, [])

  const [data, setData] = useState({})
  useEffect(() => {
    ;(async function () {
      try {
        setLoading(true)
        const { data, labels } = await app.service('stats').create({
          from,
          to,
          range,
          groupBy: type
        })
        setData({
          labels,
          data
        })
      } catch (e) {
        console.error('error in pulling data === ', e)
      } finally {
        setLoading(false)
      }
    })()
  }, [from, to, range])

  return { data, isLoading }
}

export default useData
