import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import startCase from 'lodash/startCase'

const Title = ({ record, source, resource }) => {
  return (
    <span>
      {startCase(resource)} {record ? `"${get(record, source)}"` : ''}
    </span>
  )
}

Title.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  resource: PropTypes.string
}

export default Title
