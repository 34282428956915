import { usePermissions } from 'react-admin'
import { useEffect, useState } from 'react'
import client from '../client/feathersClient'

const useConfig = () => {
  const { permissions: userId } = usePermissions()
  const [companyRes, setCompanyRes] = useState()

  useEffect(() => {
    if (!userId) return
    ;(async function () {
      const res = await client.service('companies').get(userId)
      if (res) {
        setCompanyRes(res)
      }
    })()
  }, [userId])

  return {
    company: companyRes,
    services:
      companyRes?.services?.map((service) => ({
        ...service,
        id: service._id,
        name: service.name,
        value: service.price
      })) || [],
    labors:
      companyRes?.labors?.map((labor) => ({
        ...labor,
        id: labor._id,
        value: labor.price,
        name: labor.name
      })) || [],
    permits:
      companyRes?.permits?.map((permit) => ({
        ...permit,
        id: permit._id,
        name: permit.name
      })) || [],
    jobTypes:
      companyRes?.jobTypes?.map((jobType) => ({
        ...jobType,
        id: jobType._id,
        name: jobType.name
      })) || [],
    leadSource:
      companyRes?.leadSource?.map((source) => ({
        ...source,
        id: source._id,
        name: source.name
      })) || []
  }
}

export default useConfig
