import React, { useEffect, useState } from 'react'
import {
  FileInput as RAFileInput,
  SelectInput,
  TextField,
  usePermissions,
  useNotify
} from 'react-admin'
import camelCase from 'lodash/camelCase'
import XLSX from 'xlsx'
import { useForm, useFormState } from 'react-final-form'
import client from '../../client/feathersClient'
import { Button } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { EBidElementTypes } from '../bids/enums'
import startCase from 'lodash/startCase'
import ProgressOverlay from '../../elements/ProgressOverlay'

const FileInput = (props) => {
  const { permissions: userId } = usePermissions()
  const notify = useNotify()
  const form = useForm()
  const { values: formValues } = useFormState()
  const [company, setCompany] = useState()
  const [isFileOpen, setFileOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [items, setItems] = useState([])
  const [parts, setParts] = useState([])

  useEffect(() => {
    ;(async function () {
      if (!userId) return
      const res = await client.service('companies').get(userId)
      setCompany(res)
    })()
  }, [userId])

  useEffect(() => {
    ;(async function () {
      if (!items.length) return
      const partsRes = await client.service('parts').find({
        query: {
          itemID: { $in: items.map((el) => el.itemID) },
          $limit: 10000
        }
      })
      setParts(partsRes.data)
    })()
  }, [items])

  useEffect(() => {
    if (!items.length) return
    ;(async function () {
      const markup =
        formValues.fileType === EBidElementTypes.parts
          ? company?.config?.partsMarkup || 0
          : company?.config?.equipMarkup || 0

      let number = 0
      form.change('elements', [
        ...(formValues.elements || []),
        ...items
          .map((el) => {
            const part = parts.find((part) => part.itemID === el.itemID)
            if (!part) return
            number++
            return {
              name: `${part.itemID} - ${part.description}`,
              value: part.price * Number(1 + markup / 100),
              qty: el.qty,
              itemID: el.itemID,
              type: formValues.fileType,
              options: formValues.options.map((option) => option.id) || []
            }
          })
          .filter((el) => el)
      ])
      notify(`Added ${number} items to the bid.`, 'info', {}, true, 2000)

      form.change('fileType', null)
      form.change('file', null)
      setFileOpen(false)
      setItems([])
      setLoading(false)
    })()
  }, [parts])

  return (
    <ProgressOverlay show={isLoading}>
      <Button
        variant='outlined'
        color='default'
        endIcon={isFileOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        onClick={() => setFileOpen(!isFileOpen)}
        style={{
          margin: '16px 0'
        }}
      >
        Import from file
      </Button>
      <div
        style={{
          display: isFileOpen ? 'block' : 'none',
          opacity: isLoading ? 0.4 : 1
        }}
      >
        <SelectInput
          source={'fileType'}
          initialValue={EBidElementTypes.equipment}
          choices={[
            {
              id: EBidElementTypes.equipment,
              name: startCase(EBidElementTypes.equipment)
            },
            {
              id: EBidElementTypes.parts,
              name: startCase(EBidElementTypes.parts)
            }
          ]}
        />
        <RAFileInput
          labelSingle={'Upload .xlsx file with parts/equipment data'}
          helperText={
            'File will be attached to existing bid items. Items with ID not listed in "Parts" list will be omitted.'
          }
          source={'file'}
          {...props}
          type='file'
          accept={
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          }
          onChange={(file) => {
            if (!file) return
            const reader = new FileReader()
            reader.onload = async (evt) => {
              try {
                setLoading(true)
                const bstr = evt.target.result
                const wb = XLSX.read(bstr, { type: 'binary' })
                const ws = wb.Sheets[wb.SheetNames[0]]
                const data = XLSX.utils.sheet_to_json(ws)

                /* Find Headers */
                const headersIndex = data.findIndex((el) => {
                  return Object.values(el).includes('Line #')
                })
                const headers = data[headersIndex]

                const values = data
                  .filter((_, index) => index > headersIndex)
                  .map((el) => {
                    const values = Object.values(el)

                    const result = {}
                    const helper = {}
                    Object.values(headers).forEach((name, index) => {
                      helper[camelCase(name)] = values[index]
                    })

                    result.itemID = helper.item
                    result.qty = helper.orderQty

                    return result
                  })
                  .filter((el) => el.itemID)

                setItems(values)
              } catch (e) {
                notify(`Couldn't parse file.`, 'error', {}, true, 2000)
                setLoading(false)
              }
            }
            reader.readAsBinaryString(file)
          }}
        >
          <TextField source={'record.rawFile.name'} />
        </RAFileInput>
      </div>
    </ProgressOverlay>
  )
}

export default FileInput
