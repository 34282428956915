import React, { useEffect, useState } from 'react'
import {
  required,
  TabbedForm,
  FormTab,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  usePermissions,
  SelectInput,
  useGetList
} from 'react-admin'
import startCase from 'lodash/startCase'
import { Typography, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { EBidStatus, EElementStatus } from './enums'
import Row from './elements/Row'
import QuoteController from './elements/QuoteController'
import Quote from './elements/Quote'
import Change from '../../elements/Change'
import BidActions from './actions'
import ConnectedSelect from './elements/ConnectedSelect'
import Prompt from './Prompt'
import RichTextInput from 'ra-input-rich-text'
import useConfig from '../../hooks/useConfig'
import SummaryList from './SummaryList'
import ConfigField from './elements/ConfigField'
import { useDispatch, useSelector } from 'react-redux'
import { BLOCK_ROUTER } from '../../reducer/types'
import Toolbar from './Toolbar'
import Images from './elements/Images/Images'
import ConnectBidsImages from './ConnectBidsImages'
import Proposal from './elements/Proposal/Proposal'
import app from '../../client/feathersClient'

const useStyles = makeStyles({
  main: {
    '& .MuiFormControl-fullWidth': {
      width: '100% !important'
    }
  },
  richtext: {
    '& .ql-editor': {
      maxHeight: '407px',
      minHeight: '70px'
    }
  }
})

const validate = (values) => {
  const errors = {}
  const hasDeleted = values?.elements?.some(
    (element) => element.status === EElementStatus.deleted
  )
  if (hasDeleted) {
    errors.elements = ['Some items has been deleted.']
  }
  return errors
}

const BidChange = (props) => {
  const classes = useStyles()
  const { permissions: userId } = usePermissions()
  const templates = useGetList('bidTemplates')
  const [template, setTemplate] = useState()
  const [settings, setSettings] = useState()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const config = useConfig()
  const blockRouter = useSelector((state) => state.app.blockRouter)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: BLOCK_ROUTER, payload: true })
  }, [])

  useEffect(() => {
    ;(async function () {
      const res = await app.service('settings').find()
      setSettings(res.data)
    })()
  }, [])

  return (
    <>
      <Change {...props} actions={props.id ? <BidActions /> : null}>
        <TabbedForm
          redirect={!props.id ? 'edit' : false}
          className={classes.main}
          toolbar={<Toolbar id={props.id} />}
          validate={validate}
        >
          <FormTab label='summary'>
            <Prompt
              message={({ pathname }) => {
                if (pathname.includes('bids/')) return true
                return 'You have unsaved changes, are you sure you want to leave?'
              }}
            />
            <Row columnMobile wrapperClass={classes.richtext}>
              <Row
                wrapperStyle={
                  isSmall
                    ? {
                        display: 'block',
                        width: '100%'
                      }
                    : {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        flex: 0,
                        marginRight: '15px'
                      }
                }
              >
                <TextInput
                  source={'companyId'}
                  defaultValue={userId}
                  style={{ display: 'none' }}
                />
                <ReferenceInput
                  source='customerId'
                  reference='customers'
                  label='Customer'
                  sort={{
                    field: 'identity.name',
                    order: 'ASC'
                  }}
                  filterToQuery={(searchText) => {
                    return (
                      searchText && {
                        'identity.name[$regex]': searchText,
                        'identity.name[$options]': 'i'
                      }
                    )
                  }}
                  validate={required()}
                  fullWidth={isSmall}
                >
                  <AutocompleteInput
                    optionText='identity.name'
                    emptyText='clear search'
                  />
                </ReferenceInput>
                <ConfigField />

                <SelectInput
                  source='template'
                  choices={
                    templates?.ids?.map((id) => templates?.data[id]) || []
                  }
                  onChange={(e) => {
                    e.preventDefault()
                    const template = {
                      ...(templates?.data[e.target.value] || {})
                    }
                    delete template._id
                    delete template.createdAt
                    delete template.updatedAt
                    delete template.name

                    setTemplate(template)
                  }}
                  allowEmpty
                  fullWidth={isSmall}
                />

                <TextInput
                  source='taxJurisdiction'
                  label='Tax/permit jurisdiction'
                  fullWidth={isSmall}
                  validate={required()}
                />
                <SelectInput
                  source={'status'}
                  defaultValue={EBidStatus.pending}
                  choices={Object.values(EBidStatus).map((status) => ({
                    id: status,
                    name: startCase(status)
                  }))}
                  fullWidth={isSmall}
                />
                <SelectInput
                  choices={config.jobTypes}
                  source='jobType'
                  label='Job type'
                  fullWidth={isSmall}
                  allowEmpty
                />
              </Row>

              <RichTextInput
                source='notes'
                label='Public Proposal Notes (Seen by Customer)'
              />
            </Row>

            <TextInput
              source='privateNotes'
              label='Private Notes'
              multiline
              fullWidth
              options={{ InputProps: { rows: 2 } }}
            />

            <RichTextInput
              source='exclusions'
              label='Exclusions'
              multiline
              fullWidth
              options={{ InputProps: { rows: 2 } }}
            />

            <ConnectedSelect
              source='selectedOption'
              from={'options'}
              fullWidth={isSmall}
              allowEmpty
            />
            <SummaryList />
          </FormTab>
          <FormTab label={'items'} path={'items'}>
            <Typography variant='h6' gutterBottom>
              Choose an item to add to quote
            </Typography>

            <QuoteController
              template={template}
              setTemplate={setTemplate}
              fullWidth={isSmall}
            />
            <Quote />
          </FormTab>
          <FormTab label={'images'}>
            <Images settings={settings} />
          </FormTab>
          <FormTab label={'proposal'}>
            <Proposal />
            <ConnectBidsImages settings={settings} />
          </FormTab>
        </TabbedForm>
      </Change>
    </>
  )
}

export default BidChange
