import { useCallback } from 'react'

const useCheckBoxChange = () => {
  const getElementOptions = useCallback((element, id) => {
    const elementOptions = element.options || []
    const optionIndex = elementOptions.findIndex((elId) => elId === id)
    if (optionIndex >= 0) {
      elementOptions.splice(optionIndex, 1)
    } else elementOptions.push(id)

    return elementOptions
  }, [])

  return {
    getElementOptions
  }
}
export default useCheckBoxChange
