import { Typography } from '@material-ui/core'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'

export const formatPrice = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(Number(value || 0) / 100)
}

export const priceToNumber = (currency) => {
  return Number(currency?.replace(/[^0-9.-]+/g, ''))
}

export function roundPrice(number, digits = 2) {
  let n = Number(number)

  if (isNaN(n)) {
    throw new Error('Can not parse to number on rounding. ')
  }
  let negative = false
  if (n < 0) {
    negative = true
    n = n * -1
  }
  const multiplicator = Math.pow(10, digits + 1)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = (Math.round(n) / multiplicator).toFixed(digits)
  if (negative) {
    n = (n * -1).toFixed(digits)
  }
  return Number(n)
}

const PriceField = ({
  source,
  record,
  label,
  labelOnValue = true,
  style,
  ...props
}) => {
  const value = props.children || get(record, source)

  return (
    <div style={{ ...style }}>
      <Typography component='span' variant='caption' color='textSecondary'>
        {labelOnValue && label}
      </Typography>
      <div style={{ padding: '8px 0px 4px 0px' }}>
        <Typography component='span' variant='body2' {...props}>
          {formatPrice(value)}
        </Typography>
      </div>
    </div>
  )
}

PriceField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.object,
  labelOnValue: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default PriceField
