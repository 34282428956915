import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

import { useForm, useFormState } from 'react-final-form'
import {
  Grid,
  Typography,
  List,
  ListItem,
  Checkbox,
  IconButton,
  TextField,
  Divider
} from '@material-ui/core'
import useCheckBoxChange from './useCheckBoxChange'
import useStyles from './useStyles'
import { formatPrice } from '../../../../../elements/PriceField'
import {
  EDiscountType,
  EDiscountKind,
  EDiscountUnit,
  EElementStatus
} from '../../../enums'
import clsx from 'clsx'

const Element = ({ index, isModifier }) => {
  const styles = useStyles()
  const form = useForm()
  const { values } = useFormState()
  const { getElementOptions } = useCheckBoxChange()
  const [isEdit, setEdit] = useState(false)
  const { elements, modifiers, options } = values
  const array = useMemo(() => {
    return isModifier ? modifiers : elements || []
  }, [elements, modifiers])

  const element = useMemo(() => array[index], [array, index])

  const isLast = useMemo(() => {
    const reversed = [...array]
    reversed.reverse()
    const lastEl = reversed.find((el) =>
      element?.discount === EDiscountKind.post
        ? el.discount === EDiscountKind.post
        : el.discount !== EDiscountKind.pre
    )
    const maxIndex = array.findIndex((el) => el?.name === lastEl?.name)
    return index === maxIndex
  }, [array])

  const changeArray = useCallback(
    (func) => {
      let copy = [...array]
      const newArr = func(copy)
      form.change(isModifier ? 'modifiers' : 'elements', newArr)
    },
    [array]
  )

  if (!element) return null
  return (
    <>
      <Grid container spacing={2} className={styles.container}>
        <Grid item md={5} xs={12} className={styles.labelColumn}>
          <Typography className={styles.label}>{'Item:'}</Typography>
          <Typography
            className={clsx(
              styles.itemTitle,
              element.status ? styles[element.status] : undefined
            )}
          >
            {element.name}
          </Typography>
        </Grid>
        <div className={styles.smallColumn}>
          <Typography className={styles.label}>
            {element.qty ? 'Quantity:' : ''}
          </Typography>
          {isEdit ? (
            <TextField
              type={'number'}
              className={styles.qtyInput}
              min={0}
              defaultValue={element.qty}
              onBlur={(e) => {
                const value = e.target.value
                changeArray((arr) => {
                  arr.splice(index, 1, {
                    ...element,
                    qty: value
                  })
                  return arr
                })
                setEdit(false)
              }}
            />
          ) : (
            <Typography className={styles.title}>
              {element.qty || ''}
            </Typography>
          )}
        </div>
        <div className={styles.smallColumn}>
          <Typography className={styles.label}>
            {element.type ? 'Type:' : ''}
          </Typography>

          <Typography className={styles.title}>{element.type || ''}</Typography>
        </div>

        <div className={styles.smallColumn}>
          <Typography className={styles.label}>{'Price:'}</Typography>
          <Typography
            className={clsx(
              styles.title,
              element.status ? styles[element.status] : undefined
            )}
          >
            {element.type === EDiscountType.discount ? '-' : ''}
            {element.unit === EDiscountUnit.percent
              ? `${element.value}%`
              : formatPrice(element.value * (element.qty || 1))}
          </Typography>
        </div>

        {options.map((option, i) => {
          const checked = element.options?.find((elId) => elId === option.id)
          return (
            <div className={styles.smallColumn} key={i}>
              <List className={styles.list}>
                <ListItem className={styles.itemList}>
                  <Typography className={styles.optionMobile}>
                    <b>{`${option.name}:`}</b>
                  </Typography>
                  <Checkbox
                    onChange={() => {
                      const elementOptions = getElementOptions(
                        element,
                        option.id
                      )
                      changeArray((arr) => {
                        arr.splice(index, 1, {
                          ...element,
                          options: elementOptions
                        })
                        return arr
                      })
                    }}
                    checked={!!checked}
                    tabIndex={-1}
                    disableRipple
                  />
                  <Typography>
                    {element.unit === EDiscountUnit.percent
                      ? `(${formatPrice(element.dollarValue?.[option.id])})`
                      : ''}
                  </Typography>
                </ListItem>
              </List>
            </div>
          )
        })}
        <div className={styles.buttonContainer}>
          <IconButton
            onClick={() => {
              changeArray((arr) => {
                arr.splice(index, 1)
                return arr
              })
            }}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={() => setEdit(!isEdit)} disabled={isModifier}>
            {isEdit ? <EditOutlinedIcon /> : <EditIcon />}
          </IconButton>
        </div>
      </Grid>
      {!isLast && <Divider className={styles.divider} />}
    </>
  )
}

Element.propTypes = {
  index: PropTypes.number.isRequired
}

export default Element
