import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  TextField,
  SelectInput
} from 'react-admin'
import React from 'react'
import DateFilters from '../../elements/DateFilters'
import { EBidStatus } from './enums'
import TextLink from '../../elements/TextLink'

export const Filters = (props) => (
  <Filter {...props}>
    <SelectInput
      source='status'
      choices={Object.keys(EBidStatus).map((key) => ({
        id: key,
        name: EBidStatus[key]
      }))}
      alwaysOn
    />
    {DateFilters}
  </Filter>
)

const BidsList = (props) => (
  <List
    {...props}
    sort={{ field: 'createdAt', order: 'DESC' }}
    filters={<Filters />}
  >
    <Datagrid>
      <TextLink
        source='customer.identity.firstName'
        label={'Customer First Name'}
        linkTo={'customers'}
        linkId={'customerId'}
      />
      <TextLink
        source='customer.identity.lastName'
        label={'Customer Last Name'}
        linkTo={'customers'}
        linkId={'customerId'}
      />
      <TextField source='status' label='Status' />
      <TextField source='taxJurisdiction' label='Jurisdiction' />
      <DateField source='createdAt' showTime />
      <DateField source='updatedAt' showTime />
      <EditButton />
    </Datagrid>
  </List>
)

export default BidsList
