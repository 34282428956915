import React from 'react'
import Button from '@material-ui/core/Button'
import { TopToolbar } from 'react-admin'
import { useRef, useState } from 'react'
import lodash from 'lodash'
import XLSX from 'xlsx'
import { priceToNumber } from '../../elements/PriceField'

const BidTemplateActions = ({ data }) => {
  const inputRef = useRef()

  return (
    <TopToolbar>
      <Button
        style={{ margin: '0 8px' }}
        onClick={async () => {
          if (inputRef.current) {
            inputRef.current.click()
          }
          console.log('--------HERE--------')
        }}
      >
        import from xlsx
      </Button>
      <input
        style={{
          display: 'none'
        }}
        type='file'
        id='file'
        accept={
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
        ref={inputRef}
        onChange={(e) => {
          e.stopPropagation()
          e.preventDefault()
          const file = e.target.files[0]

          const reader = new FileReader()
          reader.onload = (evt) => {
            const bstr = evt.target.result
            const wb = XLSX.read(bstr, { type: 'binary' })
            const ws = wb.Sheets[wb.SheetNames[0]]
            const data = XLSX.utils.sheet_to_json(ws)

            /* Find Headers */
            const headersIndex = data.findIndex((el) => {
              return Object.values(el).includes('Line #')
            })
            const headers = data[headersIndex]

            const values = data
              .filter((_, index) => index > headersIndex)
              .map((el) => {
                const values = Object.values(el)

                const result = {}
                const helper = {}
                Object.values(headers).forEach((name, index) => {
                  helper[lodash.camelCase(name)] = values[index]
                })

                result.itemID = helper.item
                result.qty = helper.orderQty

                return result
              })
              .filter((el) => el.itemID)

            console.log('values ===', values)
          }
          reader.readAsBinaryString(file)
        }}
      />
    </TopToolbar>
  )
}

export default BidTemplateActions
