import { useEffect, useState } from 'react'
import Chart from 'chart.js'
import lightGreen from '@material-ui/core/colors/lightGreen'
import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'
import { EBidStatus } from '../../../models/bids/enums'
import useConfig from '../../../hooks/useConfig'
import { darken, lighten } from '@material-ui/core/styles'

const statuses = [
  { type: EBidStatus.sold, name: 'Sold', color: lightGreen[100] },
  { type: EBidStatus.pending, name: 'Pending', color: orange[100] },
  { type: EBidStatus.lost, name: 'Lost', color: red[100] }
]

const useDiagram = (ref, data, type) => {
  const [chart, setChart] = useState()
  const [elements, setElements] = useState()
  const config = useConfig()
  useEffect(() => {
    if (!config.company?._id || elements) return
    if (['jobType'].includes(type)) {
      const array = config.jobTypes
      const newEle = array.map((el) => {
        return {
          type: el.name,
          name: el.name,
          color: lighten(
            `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            0.7
          )
        }
      })
      setElements(newEle)
    } else {
      setElements(statuses)
    }
  }, [config])

  useEffect(() => {
    if (!data.data) return
    if (!elements) return

    if (!chart) {
      const chart = new Chart(ref.current, {
        type: 'bar',
        data: {
          labels: data.labels,
          datasets: elements?.map((el, i) => ({
            label: el.name,
            data: data.data?.[el.type],
            backgroundColor: el.color,
            borderColor: darken(el.color, 0.2),
            borderWidth: 1
          }))
        },
        options: {
          aspectRatio: 2,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  stepSize: 1
                }
              }
            ]
          }
        }
      })
      setChart(chart)
    }

    if (chart) {
      chart.data.datasets = elements?.map((el) => ({
        label: el.name,
        data: data.data?.[el.type],
        backgroundColor: el.color,
        borderColor: darken(el.color, 0.2),
        borderWidth: 1
      }))

      chart.data.labels = data.labels

      chart.update()
    }
  }, [data, elements])
}

export default useDiagram
