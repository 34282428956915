import React from 'react'
import { Toolbar, SaveButton } from 'react-admin'

const CompaniesEditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  )
}

export default CompaniesEditToolbar
