import { Grid, TextField, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { Add, RemoveOutlined } from '@material-ui/icons'
import RemoveSelect from './RemoveSelect'
import React from 'react'
import useStyles from '../useStyles'
import { useForm, useFormState } from 'react-final-form'
import useManageOptions from '../useManageOptions'

const Header = () => {
  const styles = useStyles()
  const form = useForm()
  const { values } = useFormState()
  const { options } = values

  const {
    addOption,
    removeOption,
    isDialogOpen,
    setDialogOpen
  } = useManageOptions()

  return (
    <Grid container spacing={2} wrap={'nowrap'} justify={'flex-end'}>
      <Grid item md={5} className={styles.flex}>
        <Typography className={styles.title}>{'Items'}</Typography>
      </Grid>
      <Typography className={styles.smallColumn}>{'Quantity'}</Typography>
      <Typography className={styles.smallColumn}>{'Type'}</Typography>
      <Typography className={styles.smallColumn}>{'Price'}</Typography>

      {options.map((option, index) => (
        <TextField
          key={option.id}
          label={`Option ${index + 1}`}
          value={option.name}
          className={styles.smallColumn}
          onChange={(event) => {
            let copy = [...options]
            copy.splice(index, 1, {
              ...option,
              name: event.target.value
            })
            form.change('options', copy)
          }}
        />
      ))}
      <div className={styles.buttonContainer}>
        <IconButton
          color='primary'
          onClick={addOption}
          disabled={options.length >= 6}
        >
          <Add />
        </IconButton>
        <IconButton
          color='default'
          onClick={() => setDialogOpen(true)}
          disabled={options.length <= 1}
        >
          <RemoveOutlined />
        </IconButton>
        <RemoveSelect
          options={options}
          isOpen={isDialogOpen}
          setOpen={setDialogOpen}
          onSubmit={removeOption}
        />
      </div>
    </Grid>
  )
}

export default Header
