import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Field, Form } from 'react-final-form'
import renderTextInput from '../../elements/renderTextInput'
import app from '../../client/feathersClient'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useNotify } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  input: {
    margin: '0.5rem 0'
  }
}))

const ResetPasswordDialog = ({ setter, isOpen, initial }) => {
  const [isTokenState, setTokenState] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const classes = useStyles()
  const notify = useNotify()
  const onSubmit = async ({ email, password, token }) => {
    try {
      setLoading(true)
      if (!isTokenState) {
        await app.service('userAuthManagement').create({
          action: 'sendResetPwd',
          value: {
            email: email
          }
        })
        setTokenState(true)
      } else {
        await app.service('userAuthManagement').create({
          action: 'resetPwdShort',
          value: {
            user: { email },
            password,
            token
          }
        })
        setTokenState(false)
        setter(false)
      }
    } catch (error) {
      console.error(error)
      notify('Could not reset password for this account', 'warning')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setter(false)}
      aria-labelledby='dialog-title'
      fullWidth
      maxWidth={'sm'}
    >
      <DialogTitle id='dialog-title'>Reset Password</DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={initial}
        render={({ handleSubmit }) => (
          <>
            <DialogContent>
              <DialogContentText>
                {!isTokenState
                  ? 'Provide your email address.'
                  : 'Reset token was sent to given email. Confirm token and enter new password. '}
              </DialogContentText>
              {!isTokenState ? (
                <Field
                  autoFocus
                  fullWidth
                  name='email'
                  component={renderTextInput}
                  label='Email Address'
                  type='email'
                />
              ) : (
                <>
                  <Field
                    autoFocus
                    fullWidth
                    name='token'
                    component={renderTextInput}
                    label='Reset token'
                    type='text'
                    className={classes.input}
                  />
                  <Field
                    fullWidth
                    name='password'
                    component={renderTextInput}
                    label='New password'
                    type='password'
                    className={classes.input}
                  />
                  <Field
                    fullWidth
                    name='confirmPassword'
                    component={renderTextInput}
                    label='Confirm password'
                    type='password'
                    className={classes.input}
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setter(false)
                  setTokenState(false)
                }}
                color='primary'
              >
                Cancel
              </Button>
              <Button color='primary' type={'submit'} onClick={handleSubmit}>
                {isLoading ? (
                  <CircularProgress />
                ) : isTokenState ? (
                  'Set new password'
                ) : (
                  'Send reset token'
                )}
              </Button>
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  )
}

export default ResetPasswordDialog
