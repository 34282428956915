import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
  loader: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

const CustomLoader = () => {
  const classes = useStyles()
  return <CircularProgress className={classes.loader} />
}

export default CustomLoader
