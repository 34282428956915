import startCase from 'lodash/startCase'

export const EBidStatus = {
  pending: 'pending',
  sold: 'sold',
  lost: 'lost'
}

export const EDiscountKind = {
  pre: 'pre',
  post: 'post'
}
export const EDiscountType = {
  fee: 'fee',
  discount: 'discount'
}
export const EDiscountUnit = {
  dollar: 'dollar',
  percent: 'percent'
}

export const EBidElementTypes = {
  equipment: 'equipment',
  parts: 'parts',
  service: 'service',
  permits: 'permits',
  labor: 'labor',
  miscellaneous: 'miscellaneous'
}

export const EElementStatus = {
  modified: 'modified',
  deleted: 'deleted'
}
export const bidElementData = Object.keys(EBidElementTypes).map((key) => {
  let name = startCase(key)

  return {
    id: key,
    name
  }
})
