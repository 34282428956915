import React from 'react'
import {
  SimpleForm,
  TextInput,
  usePermissions,
  required,
  SelectInput
} from 'react-admin'
import { Typography, useMediaQuery } from '@material-ui/core'
import QuoteController from '../bids/elements/QuoteController'
import Quote from '../bids/elements/Quote'
import Change from '../../elements/Change'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Prompt from '../bids/Prompt'
import Row from '../bids/elements/Row'
import RichTextInput from 'ra-input-rich-text'
import BidTemplateActions from './actions'
import FileInput from './FileInput'
import useConfig from '../../hooks/useConfig'

const useStyles = makeStyles({
  main: {
    '& .MuiFormControl-fullWidth': {
      width: '100% !important'
    }
  }
})

const BidTemplateChange = (props) => {
  const classes = useStyles()
  const { permissions: userId } = usePermissions()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const config = useConfig()

  return (
    <Change {...props} actions={props.id ? <BidTemplateActions /> : null}>
      <SimpleForm
        redirect={!props.id ? 'edit' : false}
        className={classes.main}
        style={{
          position: 'relative'
        }}
      >
        <TextInput
          source={'companyId'}
          defaultValue={userId}
          style={{ display: 'none' }}
        />

        {props.id ? <Prompt when={props.id} /> : null}

        <FileInput />

        <Row columnMobile wrapperClass={classes.richtext}>
          <Row
            wrapperStyle={
              isSmall
                ? {
                    display: 'block',
                    width: '100%'
                  }
                : {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    flex: 0,
                    marginRight: '15px'
                  }
            }
          >
            <TextInput
              source={'name'}
              validate={props.id && required()}
              fullWidth={isSmall}
            />
            <TextInput
              source='taxJurisdiction'
              label='Tax/permit jurisdiction'
              fullWidth={isSmall}
            />
            <SelectInput
              choices={config.jobTypes}
              source='jobType'
              label='Job type'
              fullWidth={isSmall}
            />
          </Row>

          <RichTextInput
            source='notes'
            label='Public Proposal Notes (Seen by Customer)'
          />
        </Row>

        <TextInput
          source='privateNotes'
          label='Private Notes'
          multiline
          fullWidth
          options={{ InputProps: { rows: 2 } }}
        />

        <RichTextInput
          source='exclusions'
          label='Exclusions'
          multiline
          fullWidth
          options={{ InputProps: { rows: 2 } }}
        />

        <Typography variant='h6' gutterBottom>
          Choose an item to add to quote
        </Typography>

        <QuoteController fullWidth={isSmall} />
        <Quote isTemplate />
      </SimpleForm>
    </Change>
  )
}

export default BidTemplateChange
