import React, { useCallback } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

export default function RemoveSelect({
  isOpen,
  setOpen,
  onSubmit,
  options,
  ...props
}) {
  const [option, setOption] = React.useState('')

  const handleChange = useCallback((event) => {
    setOption(event.target.value || '')
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} {...props}>
        <DialogTitle>Choose an option to remove</DialogTitle>
        <DialogContent>
          <TextField
            select
            value={option}
            onChange={handleChange}
            style={{ width: '100%' }}
          >
            {options.map((opt) => {
              return (
                <MenuItem key={opt.id} value={opt.id}>
                  {opt.name}
                </MenuItem>
              )
            })}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSubmit(option)
              handleClose()
            }}
            color='primary'
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
