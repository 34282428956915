import React from 'react'
import { Labeled } from 'react-admin'
import ImageInput from '../../../../elements/ImageInput'

const Proposal = (props) => {
  return (
    <>
      <Labeled label='Provider Image'>
        <ImageInput editable source='providerImage' />
      </Labeled>

      <Labeled label='Main Image'>
        <ImageInput editable source='mainImage' />
      </Labeled>
    </>
  )
}

export default Proposal
