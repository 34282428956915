import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CardWithIcon from './CardWithIcon'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import CloseIcon from '@material-ui/icons/Close'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import { makeStyles } from '@material-ui/core/styles'
import { lightGreen, orange, lightBlue } from '@material-ui/core/colors'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useGetList } from 'react-admin'
import useBidsStats from './useBidsStats'
import useLatestBids from './useLatestBids'
import { stringify } from 'query-string'
import { EBidStatus } from '../../models/bids/enums'
import ColumnDiagram from './ColumnDiagram'
import useConfig from '../../hooks/useConfig'

const useStyles = makeStyles(() => ({
  container: {
    margin: '1rem 0'
  },
  spinner: {
    display: 'block',
    margin: '20px auto'
  }
}))

const Dashboard = () => {
  const classes = useStyles()
  const stats = useBidsStats()
  const { bids, loadingBids } = useLatestBids()
  const { jobTypes } = useConfig()

  const { data: customers, loading: loadingCustomers } = useGetList(
    'customers',
    {
      page: 1,
      perPage: 5
    },
    {
      field: 'createdAt',
      order: 'DESC'
    }
  )

  return (
    <Container maxWidth={false} className={classes.container}>
      <Typography variant={'h6'} component={'h5'}>
        Bids in the last 30 days
      </Typography>
      <Grid container spacing={2} direction={'row'}>
        <Grid item container spacing={2} direction={'column'} xs={12} lg={3}>
          <Grid item>
            <CardWithIcon
              icon={DoneAllIcon}
              title={'Sold'}
              subtitle={stats.soldTotal}
              color={lightGreen[500]}
              to={{
                pathname: '/bids',
                search: stringify({
                  filter: JSON.stringify({ status: EBidStatus.sold })
                })
              }}
            />
          </Grid>
          <Grid item>
            <CardWithIcon
              icon={TimelapseIcon}
              title={'Pending'}
              subtitle={stats.pendingTotal}
              color={orange[500]}
              to={{
                pathname: '/bids',
                search: stringify({
                  filter: JSON.stringify({ status: EBidStatus.pending })
                })
              }}
            />
          </Grid>
          <Grid item>
            <CardWithIcon
              icon={CloseIcon}
              title={'Lost'}
              subtitle={stats.lostTotal}
              to={{
                pathname: '/bids',
                search: stringify({
                  filter: JSON.stringify({ status: EBidStatus.lost })
                })
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={5}>
          <CardWithIcon
            icon={PeopleAltIcon}
            title={'Newest customers'}
            color={lightBlue[300]}
          >
            {loadingCustomers ? (
              <CircularProgress className={classes.spinner} />
            ) : (
              Object.values(customers).map((customer) => {
                const address = `${customer.address?.state || ''}${
                  customer.address?.state && customer.address?.city ? ',' : ''
                } ${customer.address?.city || ''}`
                return (
                  <List key={customer._id}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <PeopleAltIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={customer.identity?.name || 'User name'}
                        secondary={address}
                      />
                      <ListItemSecondaryAction>
                        <Button
                          component={Link}
                          to={`/customers/${customer._id}`}
                        >
                          See
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                )
              })
            )}
          </CardWithIcon>
        </Grid>
        <Grid item xs={12} lg={4}>
          <CardWithIcon
            icon={AttachMoneyIcon}
            title={'Newest bids'}
            color={lightBlue[900]}
          >
            {loadingBids ? (
              <CircularProgress className={classes.spinner} />
            ) : (
              bids.map((bid) => {
                return (
                  <List key={bid._id}>
                    <ListItem>
                      <ListItemText
                        primary={`Bid for ${bid.customerName}`}
                        secondary={`${bid.status}${
                          bid.jobType &&
                          jobTypes.find(
                            (jobType) => bid.jobType === jobType._id
                          )
                            ? ', ' +
                              jobTypes.find(
                                (jobType) => bid.jobType === jobType._id
                              )?.name
                            : ''
                        }`}
                      />
                      <ListItemSecondaryAction>
                        <Button component={Link} to={`/bids/${bid._id}`}>
                          See
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                )
              })
            )}
          </CardWithIcon>
        </Grid>
      </Grid>
      <ColumnDiagram type={'status'} />
      <ColumnDiagram type={'jobType'} />
    </Container>
  )
}

export default Dashboard
