import useConfig from '../../../../hooks/useConfig'
import { EBidElementTypes } from '../../enums'
import useDemo from './useDemo'

const useType = (type) => {
  const { services, labors, company, permits } = useConfig()
  const parts = [] // handled in Autocomplete
  const equipment = [] // handled in Autocomplete
  const partsDemo = useDemo(company?.config?.partsMarkup)
  const equipmentDemo = useDemo(company?.config?.equipMarkup)

  switch (type) {
    case EBidElementTypes.service:
      return services
    case EBidElementTypes.labor:
      return labors
    case EBidElementTypes.permits:
      return permits
    case EBidElementTypes.equipment:
      if (company?._id && !company?.gensco?.customerID) {
        return equipmentDemo
      }
      return equipment
    case EBidElementTypes.parts:
      if (company?._id && !company?.gensco?.customerID) {
        return partsDemo
      }
      return parts
  }
}

export default useType
