import React from 'react'
import CustomLoader from '../../../../elements/Loader'
import TitlebarGridList from '../../../../elements/TitlebarGridList'

const Images = (props) => {
  if (!props.settings) return <CustomLoader />

  let filteredData

  const data = props.settings.find((el) => el.type === 'images')
  if (data) {
    filteredData = data.data.map((el) => {
      return {
        img: el?.url,
        title: el?.title
      }
    })
  }

  if (!filteredData) return 'No data'

  return (
    <>
      <TitlebarGridList data={filteredData} />
    </>
  )
}

export default Images
