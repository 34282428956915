import React from 'react'
import { UserMenu, MenuItemLink, AppBar, usePermissions } from 'react-admin'
import SettingsIcon from '@material-ui/icons/Settings'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const CustomUserMenu = (props) => {
  const { permissions } = usePermissions()

  return (
    <UserMenu {...props}>
      <MenuItemLink
        to={`/companies/${permissions}`}
        primaryText='Configuration'
        leftIcon={<SettingsIcon />}
      />
    </UserMenu>
  )
}

const useStyles = makeStyles({
  flex: {
    flex: 1
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
})

const MyAppBar = (props) => {
  const classes = useStyles()
  return (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
      <Typography
        variant='h6'
        id='react-admin-title'
        className={classes.title}
      />

      <Typography variant='h6'>PreciseBid</Typography>

      <span className={classes.flex} />
    </AppBar>
  )
}

export default MyAppBar
