import * as React from 'react'
import { SaveButton, Toolbar as RaToolbar, useCreate } from 'react-admin'
import { useDispatch } from 'react-redux'
import { BLOCK_ROUTER } from '../../reducer/types'
import { Button, Typography } from '@material-ui/core'

const Toolbar = (props) => {
  const templateData = { ...props.record }
  delete templateData._id
  delete templateData.id
  delete templateData.createdAt
  delete templateData.updatedAt
  const [create, { loaded, loading, error }] = useCreate(
    'bidTemplates',
    templateData
  )
  const dispatch = useDispatch()
  return (
    <RaToolbar {...props}>
      <SaveButton
        onClick={() => {
          !props.id && dispatch({ type: BLOCK_ROUTER, payload: false })
        }}
        redirect={!props.id ? 'edit' : false}
        style={{ marginRight: 10 }}
      />
      {props.id && (
        <Button
          onClick={create}
          disabled={loading || !templateData.companyId}
          variant='outlined'
          style={{ marginRight: 10 }}
        >
          Save as template
        </Button>
      )}

      {error && (
        <Typography variant='caption' color='error'>
          An error occurred, please try again
        </Typography>
      )}
      {loaded && (
        <Typography variant='caption' color='primary'>
          Template created!
        </Typography>
      )}
    </RaToolbar>
  )
}

export default Toolbar
