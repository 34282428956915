import { useForm, useFormState } from 'react-final-form'
import { v4 as uuidv4 } from 'uuid'
import { useCallback, useState } from 'react'

const useManageOptions = () => {
  const form = useForm()
  const { values } = useFormState()
  const [isDialogOpen, setDialogOpen] = useState(false)
  const { options, elements, images } = values

  const addOption = useCallback(() => {
    form.change('options', [
      ...options,
      {
        id: uuidv4(),
        name: `option${options.length + 1}`
      }
    ])
  }, [options])

  const removeOption = (id) => {
    const copy = [...options]
    const index = copy.findIndex((option) => option.id === id)
    copy.splice(index, 1)
    form.change('options', copy)

    if (elements) {
      const newElements = elements.map((element) => ({
        ...element,
        options: element.options.filter((elId) => elId !== id)
      }))

      form.change('elements', newElements)
    }

    // remove assigned image field (set to null)
    if (images) {
      form.change(`images.${id}`, undefined)
    }
  }

  return {
    addOption,
    removeOption,
    setDialogOpen,
    isDialogOpen
  }
}

export default useManageOptions
