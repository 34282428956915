import {
  Edit,
  NumberInput,
  required,
  TextInput,
  TabbedForm,
  FormTab,
  Labeled
} from 'react-admin'
import { Typography } from '@material-ui/core'
import useCommonStyles from '../../hooks/useCommonStyles'
import React from 'react'
import CompaniesEditToolbar from './Toolbar'
import RichText from './RichText'
import ServicesTab from './Tabs/Services'
import LaborsTab from './Tabs/Labors'
import PermitsTab from './Tabs/Permits'
import JobTypesTab from './Tabs/JobTypes'
import LeadSourceTab from './Tabs/LeadSource'
import ImageInput from '../../elements/ImageInput'
import RichTextInput from 'ra-input-rich-text'

const CompanyEdit = (props) => {
  const classes = useCommonStyles()
  return (
    <Edit {...props} title={'Configuration'}>
      <TabbedForm toolbar={<CompaniesEditToolbar />} redirect={false}>
        <FormTab label='Summary'>
          <TextInput
            source='email'
            label='Email'
            type='email'
            validate={required()}
            disabled
          />
          <TextInput
            source='companyName'
            label='Company Name'
            validate={required()}
          />

          <Typography variant='h6' gutterBottom>
            Company Information
          </Typography>
          <TextInput
            source='address.address1'
            label='Address'
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source='address.address2'
            label='Address (line 2)'
            formClassName={classes.inlineBlock}
          />
          <div />
          <TextInput
            source='address.city'
            label='City'
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source='address.state'
            label='State'
            formClassName={classes.inlineBlock}
          />
          <TextInput
            source='address.zip'
            label='Zip'
            formClassName={classes.inlineBlock}
          />
          <TextInput source='phone' />
          <TextInput source='website' />
          <Typography variant='h6' gutterBottom>
            Bid configuration
          </Typography>
          <Typography variant='subtitle2' className={classes.fullWidth}>
            {
              'Information in this section will be visible to your customers. It will be added to bids.'
            }
          </Typography>
          <RichText />
          <NumberInput
            source='config.equipMarkup'
            label='Equipment markup'
            helperText={
              'In Percent (For example, if you enter 50, your equipment will be marked up by 50%)'
            }
            formClassName={classes.inlineBlock}
          />
          <NumberInput
            source='config.partsMarkup'
            label='Parts markup'
            helperText={
              'In Percent (For example, if you enter 50, your parts will be marked up by 50%)'
            }
            formClassName={classes.inlineBlock}
          />

          <Labeled label='Logo'>
            <ImageInput editable source='logo' />
          </Labeled>

          <RichTextInput
            source='proposalDescription'
            label='Proposal Description'
            fullWidth
          />
        </FormTab>

        <ServicesTab />
        <LaborsTab />
        <PermitsTab />
        <JobTypesTab />
        <LeadSourceTab />
      </TabbedForm>
    </Edit>
  )
}

export default CompanyEdit
