import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(() => ({
  container: {
    display: 'inherit',
    width: '100%',
    height: '100%',
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.01)',
    minWidth: '40px',
    minHeight: '40px'
  },
  spinner: {
    display: 'block',
    position: 'absolute',
    width: '40px',
    height: '40px',
    top: 'calc(50% - 20px);',
    left: 'calc(50% - 20px);'
  },
  blur: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backdropFilter: 'blur(10px)'
  }
}))

const ProgressOverlay = ({ children, show, ...props }) => {
  const classes = useStyles()
  return (
    <div className={show && classes.container}>
      <div className={show && classes.blur} />
      {show && <CircularProgress className={classes.spinner} {...props} />}
      {children}
    </div>
  )
}

export default ProgressOverlay
