import React from 'react'
import PropTypes from 'prop-types'
import isArray from 'lodash/isArray'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flex: 1
  },
  column: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  }
}))

const Row = ({
  children,
  columnMobile,
  wrapperStyle,
  wrapperClass,
  ...props
}) => {
  const styles = useStyles()

  const childrenArr = isArray(children) ? children : [children]
  const className = clsx(styles.row, wrapperClass)
  return (
    <div
      className={columnMobile ? clsx(className, styles.column) : className}
      style={wrapperStyle}
    >
      {childrenArr.map((child, index) => {
        return React.cloneElement(child, { ...props, key: index })
      })}
    </div>
  )
}

Row.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  wrapperStyle: PropTypes.object,
  columnMobile: PropTypes.bool
}

export default Row
