import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  card: {
    flex: 3,
    flexBasis: 1,
    margin: '15px',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    overflowX: 'scroll'
  },
  flex: { display: 'flex' },
  title: {
    alignSelf: 'center',
    justifySelf: 'flex-start',
    margin: '0 auto 0 6px'
  },
  secondaryTitle: {
    margin: '0 auto 0 6px'
  },
  taxInput: { justifyContent: 'flex-start', display: 'flex' },
  buttonContainer: {
    padding: '8px !important',
    position: 'relative',
    top: '-8px',
    display: 'flex',
    justifyContext: 'center',
    alignItems: 'center',
    width: '112px'
  },

  boldTitle: {
    textAlign: 'center',
    fontWeight: 700
  },
  spacedRow: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  mobileContainer: {
    margin: '32px 0',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  alignCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mobileCard: { marginTop: '16px' },
  smallColumn: {
    width: '100px',
    margin: '0 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bigBreak: {
    width: '372px'
  },
  smallBreak: {
    width: '128px'
  }
}))

export default useStyles
