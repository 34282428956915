import { Prompt as RRPrompt } from 'react-router'
import React from 'react'
import { useFormState } from 'react-final-form'

const Prompt = ({ message }) => {
  const { modified } = useFormState()

  return (
    <RRPrompt
      when={Object.values(modified)?.some((v) => v)}
      message={
        message || 'You have unsaved changes, are you sure you want to leave?'
      }
    />
  )
}

export default Prompt
