import * as React from 'react'
import { createElement } from 'react'
import { Card, Box, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  card: {
    minHeight: 52,
    flex: '1',
    '& a': {
      textDecoration: 'none',
      color: 'inherit'
    }
  },
  main: (props) => ({
    overflow: 'inherit',
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .icon': {
      color: props.color || '#dc2440'
    }
  }),
  title: {}
}))

const CardWithIcon = (props) => {
  const { icon, title, subtitle, to, children } = props
  const classes = useStyles(props)
  const Wrapper = (props) =>
    to ? <Link to={to} {...props} /> : <React.Fragment {...props} />
  return (
    <Card className={classes.card}>
      <Wrapper>
        <div className={classes.main}>
          <Box width='3em' className='icon'>
            {createElement(icon, { fontSize: 'large' })}
          </Box>
          <Box textAlign='right'>
            <Typography className={classes.title} color='textSecondary'>
              {title}
            </Typography>
            <Typography variant='h5' component='h2'>
              {subtitle ?? ''}
            </Typography>
          </Box>
        </div>
      </Wrapper>
      {children && <Divider />}
      {children}
    </Card>
  )
}

export default CardWithIcon
