import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
  },
  gridList: {
    maxHeight: 1000,
    width: '100%'
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  }
}))

export default function TitlebarGridList({ data, height = 200, cols = 4 }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <GridList cols={cols} cellHeight={height} className={classes.gridList}>
        {data.map((tile) => (
          <GridListTile key={tile.img}>
            <img src={tile.img} alt={tile.title} />
            <GridListTileBar title={tile.title} />
          </GridListTile>
        ))}
      </GridList>
    </div>
  )
}
